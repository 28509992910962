import { addDays } from 'date-fns';
import { DateRange } from 'react-date-range';
import React, {useRef, useMemo, useState, useCallback } from "react";
import en_PK from 'date-fns/locale/en-IN';

function DataRange(props) {
    const ref = useRef(null);
    
    const element = ref.current;
    console.log(element);
    //element.addEventListener('click', handleClick);

    const [to, setTo] = useState(addDays(new Date(), 2));
    const [from, setFrom] = useState(new Date());

    const handleSelect = useCallback(({ selection: { startDate, endDate } }) => {
        setFrom(startDate);
        setTo(endDate);
    });
    const ranges = useMemo(() => {
        return [
            {
                startDate: from,
                endDate: to,
                key: "selection"
            }
        ];
    }, [from, to]);
    if (props.display) {
        const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = new Date(from.toISOString());
        const secondDate = new Date(to.toISOString());
        var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        var fromMonth = new Date(from.toISOString()).toLocaleString('en-pk', { weekday: 'short', month: 'short' });
        var toMonth = new Date(to.toISOString()).toLocaleString('en-pk', { weekday: 'short', month: 'short' });
        var checkIn = `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`;
        var checkOut = `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`;

        return <div>

            <div className="dateRangeCont">
                <DateRange
                    locale={en_PK}
                    color={"#EF4E22"}
                    rangeColors={"red"}
                    minDate={new Date()}
                    dateDisplayFormat={"yyyy.MM.dd"}
                    onChange={handleSelect}
                    moveRangeOnFirstSelection={false}
                    ranges={ranges}
                    months={1}
                    direction="vertical"
                />
               <p className="cincout" style={{ fontSize: "14px" }}><span className='fouth-heading'>{'Checkin: '}</span>{new Date(checkIn).toLocaleString('en-pk', { weekday: 'short', month: 'short' })}{' '}{new Date(checkIn).getDate()}{' '} - <span className='fouth-heading'>{' Checkout: '}</span>{new Date(checkOut).toLocaleString('en-pk', { weekday: 'short', month: 'short' })}{' '}{new Date(checkOut).getDate()}</p>
                  <div className="dataDoneBtn-container">
                    <button style={{ outline: 'none' }} className="btn btn-primary-seach box-shadow-2 my-3" type="submit" onClick={props.handleClose}>Done</button>
                  </div>
            </div>

        </div>
    }
}

export default DataRange
