import React, { Component } from "react";
// import './destination.css';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { addDays } from "date-fns";
import StarRateIcon from "@mui/icons-material/StarRate";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { AiFillStar } from "react-icons/ai";
import { click_top_hotel } from "../../../OrangeLoftGTM";
import { fetch_topcities } from "../../../server";

function destinations() {
  const [viewAll, setviewAll] = useState(false);
  const [responseArray, setresponseArray] = useState([]);

  function diff_days(chk, out) {
    let dt1 = new Date(chk);
    let dt2 = new Date(out);
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  }

  const fetchApiData = async () => {
    const data = await fetch_topcities();
    setresponseArray(data.Collection.Rows);
  };
  useEffect(() => {
    fetchApiData();
  }, []);

  return (
    <section className="destContFullD mtt-3">
      <div className="container oo_outer">
        <div className="row">
          <div xs={12} className="">
            <h5 className="text-center main-heading mb-4">
              Most Popular Destinations
            </h5>
          </div>
        </div>
        {isMobile ? (
          <div>
            {responseArray.length !== 0 ? (
              <div className="visbale-destination">
                {responseArray.map((city, index) => {
                  if (viewAll) {
                    return (
                      <Link
                        onClick={() =>
                          click_top_hotel(
                            city.CityName,
                            city.AccommodationId,
                            city.AccommodationName
                          )
                        }
                        to={`/hotels&city=${city.CityName.replace(
                          /<(.|\n)*?>/g,
                          ""
                        )}&check_in=${new Date().getFullYear()}-${
                          new Date().getMonth() + 1
                        }-${new Date().getDate()}&check_out=${new Date().getFullYear()}-${
                          new Date().getMonth() + 1
                        }-${
                          new Date().getDate() + 1
                        }&adult=2&rooms=1&nights=1&hotel_id=${
                          city.AccommodationId
                        }`}
                      >
                        <div className="d-flex property-destination position-relative box-shadow-2">
                          {/* <span className="heart"><FavoriteBorderIcon className="f-15" /></span> */}
                          <img
                            className="cityimage box-shadow-1"
                            src={
                              city.Image
                                ? `https://roomph.ascendant.travel/${city.Image}`
                                : "https://www.rumblerum.com/wp-content/uploads/2019/09/sweet-tooth-3.jpg"
                            }
                            alt=""
                          />
                          <span className="desc-rightr">
                            <span className="cityNameCount">
                              <span className="des_p_name ml-3 mb-1">
                                {city.AccommodationName}
                              </span>
                              <p className="ml-3 f-10 propert-text">
                                {city.Description}
                              </p>
                            </span>
                            <div className="ml-3 mt-2 d-flex align-items-center">
                              <AiFillStar className="f-13 Primary-color" />
                              &nbsp;
                              <span className="Primary-color">
                                {city.ReviewScore
                                  ? parseFloat(city.ReviewScore).toFixed(1)
                                  : "4"}
                              </span>
                              <span className="f-12 black-color">
                                &nbsp;(
                                {city.ReviewNo ? city.ReviewNo : "3600 Reviews"}
                                )
                              </span>
                            </div>
                          </span>
                        </div>
                      </Link>
                    );
                  } else {
                    if (index < 3) {
                      return (
                        <Link
                          to={`/hotels&city=${city.CityName.replace(
                            /<(.|\n)*?>/g,
                            ""
                          )}&check_in=${new Date().getFullYear()}-${
                            new Date().getMonth() + 1
                          }-${new Date().getDate()}&check_out=${new Date().getFullYear()}-${
                            new Date().getMonth() + 1
                          }-${
                            new Date().getDate() + 1
                          }&adult=2&rooms=1&nights=1&hotel_id=${
                            city.AccommodationId
                          }`}
                        >
                          <div className="d-flex property-destination position-relative box-shadow-2">
                            {/* <span className="heart"><FavoriteBorderIcon className="f-15" /></span> */}
                            <img
                              className="cityimage box-shadow-1"
                              src={
                                city.Image
                                  ? `https://roomph.ascendant.travel/${city.Image}`
                                  : "https://www.rumblerum.com/wp-content/uploads/2019/09/sweet-tooth-3.jpg"
                              }
                              alt=""
                            />
                            <span className="desc-rightr">
                              <span className="cityNameCount">
                                <span className="des_p_name ml-3 mb-1">
                                  {city.AccommodationName}
                                </span>
                                <p className="ml-3 f-10 propert-text">
                                  {city.Description}
                                </p>
                              </span>
                              <div className="ml-3 mt-2 d-flex align-items-center">
                                <AiFillStar className="f-13 Primary-color" />
                                &nbsp;
                                <span className="Primary-color">
                                  {city.ReviewScore
                                    ? parseFloat(city.ReviewScore).toFixed(1)
                                    : "4"}
                                </span>
                                <span className="f-12 black-color">
                                  &nbsp;(
                                  {city.ReviewNo
                                    ? city.ReviewNo
                                    : "3600 Reviews"}
                                  )
                                </span>
                              </div>
                            </span>
                          </div>
                        </Link>
                      );
                    }
                  }
                })}
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary-2 mt-2"
                    onClick={() => {
                      setviewAll(!viewAll);
                    }}
                  >
                    {viewAll ? "View Less" : "View More"}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            {responseArray.length !== 0 ? (
              <OwlCarousel
                className="owl-theme mb-3 destination-caroisal"
                nav
                items={5}
                dots={false}
                responsiveClass={true}
              >
                {responseArray.map((city, index) => {
                  return (
                    <Link
                      onClick={() =>
                        click_top_hotel(
                          city.CityName,
                          city.AccommodationId,
                          city.AccommodationName
                        )
                      }
                      to={`/hotels&city=${city.CityName.replace(
                        /<(.|\n)*?>/g,
                        ""
                      )}&check_in=${new Date().getFullYear()}-${
                        new Date().getMonth() + 1
                      }-${new Date().getDate()}&check_out=${new Date().getFullYear()}-${
                        new Date().getMonth() + 1
                      }-${
                        new Date().getDate() + 1
                      }&adult=2&rooms=1&nights=1&hotel_id=${
                        city.AccommodationId
                      }`}
                    >
                      <div class="des_item" key={index}>
                        <img
                          className="cityPic"
                          src={
                            city.Image
                              ? `https://roomph.ascendant.travel/${city.Image}`
                              : "https://www.rumblerum.com/wp-content/uploads/2019/09/sweet-tooth-3.jpg"
                          }
                          alt=""
                        />
                        <span>
                          <p>{city.AccommodationName}</p>
                        </span>
                        <p className="destination-pp">{city.Description}</p>
                      </div>
                    </Link>
                  );
                })}
              </OwlCarousel>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default destinations;
