import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import Topbar from "../Topbar/Topbar";
import SearchBox from "./search/SearchBox";
import OffersSlider from './slider/OffersSlider';
import Destination from './destinations/destinations';
import Explore from './explore/explore';
import Reviews from './reviews/Review';
import Footer from "../footer/Footer";
import RoomphBlogs from "./blogs/RoomphBlogs";
import Calltoaction from "../Calltoaction/Calltoaction";
import { BsWhatsapp } from "react-icons/bs";
import { page_view } from "../../OrangeLoftGTM";
import Faqs from "../FAQS/Faqs";
//BsWhatsapp
function HomePage() {
  const [user, setUser] = useState('');
  page_view('Home');

  return (
    <div className="HomePage">
      <a href="https://wa.me/+923021115638?text=" target="_blank">
        <div className="icon-bar">
          <BsWhatsapp width={25} height={25} />
        </div>
      </a>

      <Topbar page="home" user={user} setUser={setUser} />
      <SearchBox />
      <OffersSlider />
      <Destination />
      <Explore />
      <RoomphBlogs />
      <Reviews />
      <Faqs />
      <Calltoaction />
      <Footer />
    </div>
  );

}

export default HomePage;
