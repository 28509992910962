import React, { useState, useEffect } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import history from './components/history';
import Home from './components/home/home';
import PropertyDetails from './components/propertyDetails/HotelDescription';
import PropertyListing from './components/propertyListing/searchresults';
import CustomerInformation from './components/customerInformation/customerInformation';
import Thankyou from "./components/thankyou/thankyou";
import Download from './components/BasicComponents/download';
import Terms from './components/Terms-and-condition/Terms';
import About from './components/About-us/About';
import Blogs from './components/blogs/blogs';
// import Bookings from './components/bookings/bookings';
import Profile from './components/registeration/profile/profile';
import SingleBlog from './components/blogs/singleBlog';
import Maps from './components/Maps/Maps';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import OwlCarousel from 'react-owl-carousel';
import './Assests/falti-icons/flaticon.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Embessider } from './components/Embessider/Embessider';
import BlankPage from './components/BasicComponents/BlankPage';
import ErrorBoundary from './components/BasicComponents/ErrorBoundary'
import { Review } from './components/Review/Review';
import TestComponent from './components/BasicComponents/TestComponent'
import Property from './components/AddProperty/Property';
import { Booking } from './components/Booking-Details/Booking';
import { SkeletonTheme } from 'react-loading-skeleton';

function App() {

  return (
    <div className="App position-relative z-index-2">
      <div className='backdrop position-fixed w-100 h-100 z-index-0'></div>
      <ErrorBoundary >
        <SkeletonTheme baseColor="#F5F5F5" highlightColor="#DBDBDB">
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/information" component={() => <CustomerInformation />} />
          <Route path="/destination&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms&nights=:nights" component={PropertyListing} />
          <Route path="/hotels&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms&nights=:nights&hotel_id=:id" component={PropertyDetails} />
          <Route path="/thankyou" component={Thankyou} />
          <Route path="/terms-condition" component={Terms} />
          <Route path="/add-Property" component={Property} />
          <Route path="/Ambassador-Program" component={Embessider} />
          <Route path="/about-us" component={About} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/Deals" component={BlankPage} />
          <Route path="/singleblog" component={SingleBlog} />
          <Route path="/profile" component={Profile} />
          <Route path="/booking" component={Booking} />
          <Route path="/Test" component={TestComponent} />
          <Route path="/Review" component={Review} />
          <Route path="/Roomph-map&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms" component={Maps} />
          <Route path="/download" exact component={() => <Download />} />
        </Switch>
      </Router>
      </SkeletonTheme>
      </ErrorBoundary>

    </div>);

}

export default App;
