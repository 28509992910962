import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./description.css";
import history from "../../history";
import PT from "prop-types";
import { Link, withRouter } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { AiFillStar } from "react-icons/ai";

import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import { isMobile } from "react-device-detect";
import Searchheader from "../../Topbar/Searchheader";
import Skeleton from "react-loading-skeleton";
import AvailableRooms from "./AvailableRooms";
import PropertyImageSlider, {
  PropertyImageSliderLoading,
} from "./PropertyImageSlider";
import StarRateIcon from "@mui/icons-material/StarRate";
import { ReviewSlider } from "../../home/reviews/Review";
import ReactStars from "react-rating-stars-component";
import { add_to_cart } from "../../../OrangeLoftGTM";
import {
  get_accommodation_search_test,
  getaccommodationdetail,
  getuserreservations,
} from "../../../server";

function MapComponent(props) {
  if (props.nlat !== "" && props.nlong !== "") {
    var markers = [
      {
        lat: Number(props.nlat),
        lng: Number(props.nlong),
        LatLng: [
          {
            lat: Number(props.nlat),
            lng: Number(props.nlong),
          },
        ],
      },
    ];
  }

  const center = {
    lat: parseFloat(props.nlat),
    lng: parseFloat(props.nlong),
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBBnY9OgxDfjePVgEPsAasPirQSDCtFUa4",
  });
  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(10);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  let containerStyle = {
    width: "100%",
    height: isMobile ? "200px" : "400px",
  };
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
      }}
      streetViewControl={false}
      mapTypeControl={false}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {markers.map((mar, index) => {
        var position = {
          lat: mar.lat,
          lng: mar.lng,
        };
        return (
          <Marker
            key={index}
            position={position}
            icon={"https://www.roomph.pk/v1/images/marker-icon.png"}
            animation={window.google.maps.Animation.DROP}
          />
        );
      })}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

const OpenButtonWithHoc = withLightgallery(({ openGallery, ...props }) => {
  return (
    <button
      {...props}
      onClick={() => {
        openGallery("group1");
      }}
      className={["button is-primary", props.className || ""].join(" ")}
    >
      <i className="bi bi-grid-3x3-gap-fill"></i>&nbsp;&nbsp;Show all photos
    </button>
  );
});

const PhotoItem = ({ index, image, thumb, group }) => (
  <LightgalleryItem group={group} src={image} thumb={thumb}>
    {index == 0 ? (
      <img
        src={image}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: isMobile ? "10px" : "20px 0px 0px 20px",
          objectFit: "cover",
        }}
      />
    ) : index == 2 ? (
      <img
        src={image}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: isMobile ? "10px" : "0px 20px 0px 0px",
          objectFit: "cover",
        }}
      />
    ) : index == 4 ? (
      <img
        src={image}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: isMobile ? "10px" : "0px 0px 20px 0px",
          objectFit: "cover",
        }}
      />
    ) : (
      <img
        src={image}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: isMobile ? "10px" : "0px",
        }}
      />
    )}
  </LightgalleryItem>
);
PhotoItem.propTypes = {
  image: PT.string.isRequired,
  thumb: PT.string,
  group: PT.string.isRequired,
};

//main property listing container
function Description({ hotelParams, user }) {
  //ErrorRoom
  //console.log(hotelParams)
  const [anchor, setanchor] = useState(false);
  const [ErrorRoom, setErrorRoom] = useState(false);
  const [isVisiable, setisVisiable] = useState(false);
  const [bookingsDetails, setbookingsDetails] = useState(false);
  const [data, setData] = useState([]);
  const [hotelDetailsArray, sethotelDetailsArray] = useState({
    hotelBasicData: [],
    hotelDetails: [],
    setLoading: true,
  });

  const BookNow = async () => {
    //setErrorRoom
    const roomId = new Array();
    const ratePlanId = new Array();
    const extraids = new Array();
    const roomqty = new Array();
    var RefundStatusCheck = false;
    var ratesbydate = "";
    var guest_qtys = "";
    var Total = 0;
    if (data.length == 0) {
      setErrorRoom(true);
    } else {
      await data.map((datas, index) => {
        datas.RatePlanId.map((rr, rrindex) => {
          RefundStatusCheck =
            rr.RefundStatus == 2 && RefundStatusCheck == false ? true : false;
          for (let i = 0; i < rr.quantity; i++) {
            roomId.push(index);
            ratePlanId.push(rrindex);
            extraids.push(0);
            roomqty.push(rr.quantity);
            Total += Number(rr.Rate);
            if (ratesbydate != "") {
              if (i == 0) {
                ratesbydate += "|" + rr.rateBydate;
                guest_qtys += "|" + rr.MaxPerson;
              } else {
                ratesbydate += "," + rr.rateBydate;
                guest_qtys += "," + rr.MaxPerson;
              }
            } else {
              ratesbydate = rr.rateBydate;
              guest_qtys = rr.MaxPerson;
            }
          }
        });
      });
      if (Total == 0) {
        setErrorRoom(true);
      } else {
        let savedState = JSON.stringify({
          RefundStatusCheck: RefundStatusCheck,
          hotelParams: hotelParams,
          Total: Total,
          roomId: roomId.toString(),
          ratePlanId: ratePlanId.toString(),
          extraids: extraids.toString(),
          roomqty: roomqty.toString(),
          ratesbydate: ratesbydate,
          guest_qtys,
        });
        localStorage.setItem("myState", savedState);
        add_to_cart(
          Total,
          roomId.toString(),
          ratePlanId.toString(),
          roomqty.toString(),
          hotelParams.checkin,
          hotelParams.checkout,
          hotelParams.nights,
          hotelParams.adults
        );
        history.push({
          pathname: "/information",
          state: {
            RefundStatusCheck: RefundStatusCheck,
            hotelParams: hotelParams,
            Total: Total,
            roomId: roomId.toString(),
            ratePlanId: ratePlanId.toString(),
            extraids: extraids.toString(),
            roomqty: roomqty.toString(),
            ratesbydate: ratesbydate,
            guest_qtys,
          },
        });
      }
    }
  };

  const getOffset = (element) => {
    const elementReact = element ? element.getBoundingClientRect() : "";
    return elementReact;
  };

  const ListenToScroll = () => {
    const hieghtToHide = getOffset(document.getElementById("roomsC"));
    // const hieghtToHide1 = getOffset(document.getElementById("contact"));

    //nearbyyy
    const windowScrollLength =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (windowScrollLength > hieghtToHide.top) {
      setisVisiable(true);
    } else {
      setisVisiable(false);
    }
  };

  const fetchAPIData = async () => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(localStorage.getItem("user"));
      const getRes = await getuserreservations(
        foundUser.result.result.data[0].profile_id[0]
      );
      getRes.getHotels.Success[0].Result.map((book) => {
        if (book.AccommodationID == hotelParams.id) {
          setbookingsDetails(true);
          return;
        }
      });
    }
    const hotelBasicDetailData = await getaccommodationdetail(hotelParams.id);
    const request = {
      postCity: hotelParams.city,
      postCheckIn: hotelParams.checkin,
      postCheckOut: hotelParams.checkout,
      postAdults: "",
      postRooms: "",
      postPriceStart: "",
      postPriceEnd: "",
      postRating: "",
      postPremium: "",
      postCategory: "",
      userRating: "",
    };
    const hotelPOll = await get_accommodation_search_test(request);
    const searchedHotel = hotelPOll.Success.result.find(
      (h) => h.AccommodationId == hotelParams.id
    );
    sethotelDetailsArray({
      ...hotelDetailsArray,
      hotelDetails: hotelBasicDetailData.getHotels.Success[0].Result[0],
      hotelBasicData: searchedHotel,
      Loading: false,
    });
  };

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("scroll", ListenToScroll);
    }

    // fetch('/api/hotelReviews', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ postHotelId: hotelParams.id }),
    // }).then(data => data.json()).then((reviews) => {
    // console.log(reviews)
    fetchAPIData();
    // });
  }, [user]);
  function formatTime(timeString) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " am" : " pm");
  }

  return (
    <>
      {hotelDetailsArray.Loading == false ? (
        <>
          {isMobile ? (
            <Searchheader
              city={hotelParams.city}
              checkIn={hotelParams.checkin}
              checkOut={hotelParams.checkout}
              rooms={hotelParams.rooms}
              adults={hotelParams.adults}
            />
          ) : null}
          <section className="mb-4">
            {console.log(hotelDetailsArray)}
            <div className="container mt-4">
              <PropertyImageSlider
                Images={
                  hotelDetailsArray.hotelDetails.hotelImages[0].Image_URLs
                }
              />
              <div className="row mt-5 ">
                <div className="d-flex mx-2 justify-content-between">
                  <div className="hotel-name">
                    <p className="cards-des_p_name_hotels main-heading">
                      {hotelDetailsArray.hotelDetails.AccommodationName[0]}
                    </p>
                    <div className="f-12 location-text text-gray">
                      {hotelDetailsArray.hotelDetails.Address[0]}
                    </div>
                  </div>
                  <div className="hotel-orice">
                    {hotelDetailsArray.hotelBasicData.MinRate == 0 ? (
                      <p className="hotel-price">
                        <span className="main-heading">JUST MISSED IT!</span>
                      </p>
                    ) : (
                      <>
                        <div className="excellent-price-container text-right">
                          <div className="excellent-price_hotels">
                            {hotelDetailsArray.hotelBasicData.MinRate
                              ? `Rs. ${Number(
                                  hotelDetailsArray.hotelBasicData.MinRate
                                ).toLocaleString()}/-`
                              : "MISSED IT!"}
                          </div>
                          <div className="pernight">Per night</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="mx-2 mt-2">
                <p className="bold f-12">Review</p>
                {/* <p className="cards-stars">  {
                  Array.apply(null, { length: hotelDetailsArray.hotelBasicData.Rating ? Number(hotelDetailsArray.hotelBasicData.Rating[0]) : 0 }).map(Number.call, Number).map((item) => {
                    return <AiFillStar className="f-13 start-green" />
                  })
                }</p> */}
                <p className="cards-stars">
                  <ReactStars
                    count={5}
                    value={
                      hotelDetailsArray.hotelBasicData.UserRating[0] !== ""
                        ? parseFloat(
                            parseFloat(
                              hotelDetailsArray.hotelBasicData.UserRating[0]
                            ) / 2
                          ).toFixed(1)
                        : "0.0"
                    }
                    size={isMobile ? 17 : 23}
                    edit={false}
                    activeColor="#FF8500"
                    color="#C7C7C7"
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                  />
                </p>
                <div className="excellent-container black-color d-flex mt-2">
                  <div className="excellent">
                    {hotelDetailsArray.hotelBasicData.UserRating[0] !== ""
                      ? parseFloat(
                          parseFloat(
                            hotelDetailsArray.hotelBasicData.UserRating[0]
                          ) / 2
                        ).toFixed(1)
                      : "0.0"}
                  </div>
                  <div className="excellent-text">Excellent</div>
                </div>
              </div>

              <p className="cards-des_p_name_hotels main-heading mx-2 my-3">
                Location
              </p>
            </div>
            <div className="container-fluid mb-4">
              <MapComponent
                nlat={hotelDetailsArray.hotelDetails.Location[0].Latitude[0]}
                nlong={hotelDetailsArray.hotelDetails.Location[0].Longitude[0]}
              />
            </div>
            <div className="container">
              {hotelDetailsArray.hotelBasicData.MinRate == 0 ? null : (
                <div className="row mt-4" id="roomsC">
                  <div className="col-lg-8 col-md-8 col-12">
                    <p className="cards-des_p_name_hotels main-heading mx-2 mb-2 mx-2">
                      Address
                    </p>
                    <div className="f-12 location-text text-gray mx-2">
                      {hotelDetailsArray.hotelDetails.Address[0]}
                    </div>
                    <p className="cards-des_p_name_hotels main-heading mx-2 my-3">
                      Hotel Reviews
                    </p>
                    <div className="row">
                      <ReviewSlider hotelId={hotelParams.id} />
                    </div>
                    {hotelDetailsArray.Loading == false ? (
                      <AvailableRooms
                        feturedImage={
                          hotelDetailsArray.hotelDetails.hotelImages[0]
                            .Image_URLs[0].$.Image_URL
                        }
                        hotelParams={hotelParams}
                        data={data}
                        setData={setData}
                      />
                    ) : (
                      <Skeleton className="w-100 h-100 mt-2" />
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 pr-0 position-relative">
                    {isMobile ? (
                      isVisiable ? (
                        <div className="mobile-nav row">
                          <button
                            onClick={() => BookNow()}
                            className="btn btn-primary mobile-book col-6"
                          >
                            BOOK NOW
                          </button>
                        </div>
                      ) : null
                    ) : (
                      <div className="sticky-right-contrainer py-4 bg-white">
                        {ErrorRoom ? (
                          <div
                            class="alert alert-danger alert-dismissible fade show"
                            role="alert"
                          >
                            Pick a room to reserve !
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            ></button>
                          </div>
                        ) : null}

                        <div className="checkOut-information">
                          <div className="checkout-inner">
                            <button
                              className="checkin-check-out"
                              onClick={() => {
                                setanchor(false);
                              }}
                            >
                              <div className="chheckii border-right">
                                <div className="top-txt">Check-in</div>
                                <div className="input-txt">{`${new Date(
                                  hotelParams.checkin
                                ).toLocaleString("default", {
                                  month: "short",
                                })} ${new Date(
                                  hotelParams.checkin
                                ).getDate()}, ${new Date(
                                  hotelParams.checkin
                                ).getFullYear()}`}</div>
                              </div>
                              <div className="chheckii">
                                <div className="top-txt">CheckOut</div>
                                <div className="input-txt">{`${new Date(
                                  hotelParams.checkout
                                ).toLocaleString("default", {
                                  month: "short",
                                })} ${new Date(
                                  hotelParams.checkout
                                ).getDate()}, ${new Date(
                                  hotelParams.checkout
                                ).getFullYear()}`}</div>
                              </div>
                            </button>
                          </div>
                          <div className="persons checkout-inner">
                            <button
                              className="addults-combo"
                              onClick={() => {
                                setanchor(!anchor);
                              }}
                            >
                              <div className="top-txt">Guests</div>
                              <div className="input-txt">
                                {hotelParams.adults} Guest
                              </div>
                            </button>
                          </div>
                        </div>
                        <button
                          onClick={() => BookNow()}
                          className="w-100 mt-3 btn btn-primary"
                        >
                          BOOK NOW
                        </button>
                        <p className="f-12 text-center mt-2 text-gray">
                          You won't be charged yet
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="row mt-3">
                <div className="col-lg-8 col-md-8 col-12">
                  <h6 className="main-heading">
                    About {hotelDetailsArray.hotelDetails.AccommodationName}
                  </h6>
                  <ShowMoreText
                    lines={isMobile ? 5 : 3}
                    more="Show more"
                    less="Show less"
                    expanded={false}
                    className="content-css"
                    truncatedEndingComponent={"..."}
                  >
                    <p className="mt-1 hotel-general-desc">
                      {hotelDetailsArray.hotelDetails.GeneralDescription}
                    </p>
                  </ShowMoreText>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className={isMobile ? "w-100 mt-3" : ""}>
                    <h6 className="main-heading">Helpful Information</h6>
                    <p className="text-gray">
                      Check-in from{" "}
                      {formatTime(
                        hotelDetailsArray.hotelDetails.checkin[0].from[0]
                      )}
                    </p>
                    <p className="text-gray">
                      {" "}
                      Check-out from{" "}
                      {formatTime(
                        hotelDetailsArray.hotelDetails.checkout[0].from[0]
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <LodaingHotelCards />
      )}
    </>
  );
}

export function LodaingHotelCards() {
  return (
    <section className="mb-4">
      <div className="container mt-4">
        <PropertyImageSliderLoading />
      </div>
    </section>
  );
}

export default withRouter(Description);
