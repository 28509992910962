import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import { MdLocationOn } from "react-icons/md";
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { fetch_blogs } from '../../../server';
function Review() {
    const [Blogs, setblogs] = useState([]);
    const options = {
        margin: 18,
        responsiveClass: true,
        nav: false,
        loop: false,
        dots: false,
        autoplay: false,
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 2,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 3,

            }
        },
    };

    const fetchApiData = async () => {
        const data = await fetch_blogs();
        setblogs(data.Collection.Rows)
      };
      useEffect(() => {
        fetchApiData();
      }, []);
 


    return (
        <section className='mtt-3 z-0' id='blog-section'>
            <div className='container px-sm-3'>
                <h5 className="text-center main-heading mb-4 text-capitalize">Unleash the <span className='main-heading text-orange'>traveler</span> inside you</h5>
                {Blogs.length !== 0 ?
                    <OwlCarousel className='roomphblogs'  {...options}>
                        {Blogs.map((blog, index) => {
                            return (
                                <div className="blogs-outer" key={index}>
                                    <div className="blog-card box-shadow-2" >
                                        <Link to={{
                                            pathname: "/singleblog",
                                            state: { blog }
                                        }} className="link">
                                            <div className="blog-card-inner">
                                                <img className="blog-img box-shadow-2" src={`https://roomph.ascendant.travel/${blog.Image}`} alt="Avatar" />
                                                <div className="mx-3 my-3 text-center">
                                                    <div className='link px-lg-8'>{blog.Name.replace(/<(.|\n)*?>/g, '')}</div>
                                                    {/* <span className='blog-discription'><span><MdLocationOn className='f-13'/></span>{blog.Location?blog.Location.replace(/<(.|\n)*?>/g, ''):'Abbottabad Lake, Hunza'}</span> */}
                                                    <Link to={{
                                                        pathname: "/singleblog",
                                                        state: { blog }
                                                    }} className='blog-discription'>Read More...</Link>

                                                </div>

                                            </div>
                                        </Link>

                                    </div>
                                </div>

                            )

                        })}
                    </OwlCarousel>
                    : ''}
                <div className="text-center">
                    <Link to="/blogs" type="button" className="btn btn-primary-2 mt-4 position-relative z-index-0">View More</Link></div>
            </div>
        </section >

    )
}

export default Review