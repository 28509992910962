import React from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import history from ".././history";
import { HiMail } from "react-icons/hi";
//FaFacebookF
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaPhoneAlt,
} from "react-icons/fa";
import { link_click } from "../../OrangeLoftGTM";
function Footer() {
  const checkAmbassadoe = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      history.push({
        pathname: "/Ambassador-Program",
      });
    } else {
      swal({
        text: "Please log into your profile in order to see Ambasodar's profile.!",
        icon: "warning",
        buttons: false,
      });
    }
  };
  return (
    <footer
      style={{ backgroundColor: "#EA7F08", color: "#000000" }}
      className="p-2"
    >
      <div className="container footer-inner site-footer">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-12 d-flex flex-column justify-content-end">
            <h5 className="main-heading mb-2 text-uppercase">Useful Links</h5>
            <Link
              onClick={() => link_click("about-us")}
              to="/about-us"
              className="footerlink"
            >
              About Us
            </Link>
            <Link
              onClick={() => link_click("terms-condition")}
              to="/terms-condition"
              className="footerlink"
            >
              Terms and Conditions
            </Link>
            <Link
              onClick={() => link_click("blogs")}
              to="/blogs"
              className="footerlink"
            >
              Blogs
            </Link>
            <Link
              onClick={() => link_click("add-Property")}
              to="/add-Property"
              className="footerlink"
            >
              Hosting
            </Link>
          </div>

          <div className="col-lg-4 col-md-4 col-12 d-flex flex-column justify-content-end mt-lg-0 mt-md-0 mt-4">
            <h5 className="main-heading mb-2 text-uppercase">Contact Us</h5>
            <a href="mailto:reservations@orangeloft.pk" className="footerlink">
              <HiMail className="f-15" />
              &nbsp;reservations@orangeloft.pk
            </a>
            <a href="tel:+923341111192" className="footerlink">
              <FaPhoneAlt className="f-14" />
              &nbsp;+92 334 1111 192

            </a>
            <div className="footerlink">
              Head Office: 7, Abubakar Block, New Garden Town, Lahore, Punjab
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-12 d-flex flex-column justify-content-end align-items-lg-end align-items-md-end align-items-sm-start  mt-lg-0 mt-md-0 mt-4">
            <img
              className="im"
              src={process.env.PUBLIC_URL + "images/logobg.png"}
              alt="logo"
            />
            <div className="d-flex mt-2" id="contact">
              <Link
                to={{ pathname: "https://www.facebook.com/orangeloftpakistan" }}
                target="_blank"
                className="links box-shadow-1"
              >
                <FaFacebookF className="icons" />
              </Link>
              {/* <Link to={{ pathname: "https://twitter.com/orangeloftpakistan" }} target="_blank" className="links box-shadow-1"><FaTwitter className="icons" /></Link> */}
              {/* <Link to={{ pathname: "https://www.linkedin.com/company/orangeloftpakistan/about/" }} target="_blank" className="links box-shadow-1"><FaLinkedinIn className="icons" /></Link> */}
              <Link
                to={{ pathname: "https://www.instagram.com/orangeloft.pk/" }}
                target="_blank"
                className="links box-shadow-1"
              >
                <FaInstagram className="icons" />
              </Link>
            </div>
          </div>
        </div>

        {/* </div> */}
      </div>
    </footer>
  );
}

export default Footer;
