import React from 'react'

function CustomCheckBox({ value, onChange, label, className, name, id }) {
    return (

        <div className={ className}>
            <div className="d-inline-flex position-relative">
                <label className="custom-checkbox-1">
                    <input type="checkbox" className="custom-control-input" name={name} id={id} value={value} onChange={onChange} />
                    <span></span>
                </label>
                <span className="roboto-regular f-12 ml-2" style={{ position: "relative" }}>{label}</span>

                {/* </div> */}
                {/* <input type="checkbox" className="custom-control-input" name="premium" id="roomphpremium" value="5" onChange={this.handlePropertyChange.bind(this)} />
      <label className="custom-control-label" for="roomphpremium">
        <h5 className="roboto-light ft-25 pt-1">Roomph Premium <span id="label-rprem" className="type_count"></span></h5>
      </label> */}
            </div>
        </div>

    )
}

export default CustomCheckBox
