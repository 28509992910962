import React, { Component } from "react";
import {Col, Form } from "react-bootstrap";
import './registeration.css';

export class Registration extends Component {
    state = {
        email: "",
        post: '',
        responseToPost: '',
    };

    handleEmailChanged(event) {
        this.setState({
            email: event.target.value
        });
    }
    async handleButtonClicked(e) {
        e.preventDefault();
        var email = this.state.email;
        const response = await fetch('/api/forgot', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ postEmail: email }),
        })
        console.clear();
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        console.log(body);
        this.setState({ responseToPost: body });
        this.setState({
            message: body.result.data[0].message,
        })
        return body;
    }
    render() {
        return (
            <div className='sign-in-container'>
                <div className='w-100'>
                    <h6 className="text-center sub-heading mb-4">Forgot Password</h6>
                    <div className='row d-flex align-item-center'>
                        <div className="regFrom col-12">
                            <Form>
                                <Form.Group className="mt-3 mb-2" controlId="formBasicEmail">
                                    <Form.Control className="" value={this.state.email} onChange={this.handleEmailChanged.bind(this)} type="email" placeholder="Enter Email" style={{ height: "30px", borderRadius: "20px 20px 20px 20px", border: "1px solid rgb(203, 203, 203)", boxShadow: "1px 1px 1px 1px rgb(205, 206, 206)", fontFamily: "Montserrat Thin", fontSize: "12px" }} />
                                </Form.Group>
                                <div className="mb-1 aBtn m-0 p-0 col-6">
                                </div>
                                <button className="signupBtn" type="submit" onClick={this.handleButtonClicked.bind(this)}>Reset Password</button>
                            </Form>
                        </div>

                        <div className="text-center mt-3">
                            <p className="f-10">Please enter your email in the box above. We will send you a
                                link to access further instructions.</p>
                        </div>
                    </div>
                </div></div>

        )
    }
}

export default Registration;
