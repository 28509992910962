import React, { useEffect, useState } from "react";

import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { search_accommodation } from "../../../server";
function AutoSearchFromApi(props) {
  const cities = [{
    CityId: 0,
    CityName: 'Murree',
    id: 0, name: "Murree"
  },
  {
    CityId: 1,
    CityName: 'Dunga Gali',
    id: 1, name: "Dunga Gali"
  }, {
    CityId: 2,
    CityName: 'Nathia gali',
    id: 2, name: "Nathia gali"
  }, {
    CityId: 3,
    CityName: 'Shogran',
    id: 3, name: "Shogran"
  },
  {
    CityId: 4,
    CityName: 'Kalam',
    id: 4, name: "Kalam"
  },
  {
    CityId: 5,
    CityName: 'Hunza',
    id: 5, name: "Hunza"
  },

  {
    CityId: 6,
    CityName: 'Skardu',
    id: 6, name: "Skardu"
  },
  {
    CityId: 7,
    CityName: 'Swat',
    id: 7, name: "Swat Malam Jabba"
  },
  {
    CityId: 8,
    CityName: 'Kumrat',
    id: 8, name: "Kumrat"
  },
  {
    CityId: 9,
    CityName: 'Hunza',
    id: 9, name: "Hunza"
  }]
  const [city_list, setcity_list] = useState([]);

  const fetchApiData=async()=>{

    var citt = new Array();
    const data= await search_accommodation();
    data.Collection.Rows.map((ci, index) => {
      citt.push({ ...ci, id: index, name: ci.AccommodationName });
      if (index == data.Collection.Rows.length - 1) {
        setcity_list(cities.concat(citt));
        //console.log(city_list)
      }
    });
  }
  useEffect(() => {
  fetchApiData();
 
  }, []);

  const handleOnSearch = (string, results) => {
    props.setSearchData({ ...props.SearchData, showsearch: true });
  };


  const handleOnSelect = (result) => {
    props.setSearchData({
      ...props.SearchData,
      searchQuery: result,
      showsearch: !props.SearchData.showsearch,
    });
  };

  const handleOnFocus = () => {
    props.setSearchData({ ...props.SearchData, showsearch: true });
  };

  const formatResult = (items) => {
    return (
      <>
        {items.AccommodationId ? (
          <span className="d-flex align-items-center">
            <i className="flaticon-office-building text-orange mr-2"></i>
            {items.name}
            <span className="float-right"></span>
          </span>
        ) : (
          <span className="d-flex align-items-center">
            <i className="flaticon-worldwide text-orange mr-2"></i>
            {items.name}, Pakistan<span className="float-right"></span>
          </span>
        )}
      </>
    );
  };

  return city_list.length !== 0 ? (
    <ReactSearchAutocomplete
      items={city_list}
      onSearch={props.onFocus?props.onFocus:handleOnSearch}
      onSelect={props.handleOnSelect?props.handleOnSelect:handleOnSelect}
      onFocus={props.onFocus?props.onFocus:handleOnFocus}
      showItemsOnFocus={true}
      maxResults={5}
      placeholder={props.placeholder?props.placeholder:"Where do you wanna go?"}
      showIcon={false}
      formatResult={formatResult}
      styling={{
        borderRadius: "0px",
        zIndex: 90,
        width: "0px",
        fontSize: `${props.fontSize?props.fontSize:'0.75rem'}`,
        height: `${props.height?props.height:''}`,
        padding: "0px",
        margin: "0px",
        placeholderColor: "grey",
        color: "grey",
        boxShadow: "none",
      }}
    />

  ) : (
    null
  );
}

export default AutoSearchFromApi;
