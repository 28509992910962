export const page_view = (type) => {
  window.dataLayer.push({
    event: "page_view",
    ecommerce: {
      detail: {
        actionField: { page_type: type },
        products: {
          language: navigator.language,
        },
      },
    },
  });
};

export const click_top_hotel = (city, hotel_id, hotel_name) => {
  window.dataLayer.push({
    event: "click_top_hotel",
    ecommerce: {
      detail: {
        products: {
          language: navigator.language,
          hotel_name: hotel_name,
          hotel_id: hotel_id,
          city: city,
        },
      },
    },
  });
};

export const room_drop_down_select = (
  cart_price,
  RoomId,
  RoomName,
  RatePlanId,
  MaxPerson
) => {
  window.dataLayer.push({
    event: "room_drop_down_select",
    ecommerce: {
      detail: {
        products: {
          language: navigator.language,
          cart_price: cart_price,
          RoomId: RoomId,
          RoomName: RoomName,
          RatePlanId: RatePlanId,
          MaxPerson: MaxPerson,
        },
      },
    },
  });
};

export const add_to_cart = (
  Total,
  roomId,
  ratePlanId,
  roomqty,
  check_in,
  checkOut,
  nights,
  persons_quantity
) => {
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      detail: {
        products: {
          language: navigator.language,
          cart_price: Total,
          RoomId: roomId,
          RatePlanId: ratePlanId,
          rooms_quantity: roomqty,
          days_quantity: nights,
          valid_till: check_in,
          valid_from: checkOut,
          persons_quantity: persons_quantity,
        },
      },
    },
  });
};

export const confirmed_booking = (
  Total,
  roomId,
  ratePlanId,
  roomqty,
  check_in,
  checkOut,
  nights,
  persons_quantity
) => {
  window.dataLayer.push({
    event: "confirmed_booking",
    ecommerce: {
      detail: {
        products: {
          language: navigator.language,
          cart_price: Total,
          RoomId: roomId,
          RatePlanId: ratePlanId,
          rooms_quantity: roomqty,
          days_quantity: nights,
          valid_till: check_in,
          valid_from: checkOut,
          persons_quantity: persons_quantity,
        },
      },
    },
  });
};

export const link_click = (event) => {
  window.dataLayer.push({
    event: event,
  });
};

export const book_now_click = (
  event,
  city,
  check_in,
  checkOut,
  persons_quantity,
  nights,
  AccommodationId
) => {
  window.dataLayer.push({
    event: event,
    ecommerce: {
      detail: {
        products: {
          language: navigator.language,
          city: city,
          days_quantity: nights,
          valid_till: check_in,
          valid_from: checkOut,
          persons_quantity: persons_quantity,
          hotel_id: AccommodationId,
        },
      },
    },
  });
};

export const property_click = (
    city,
    check_in,
    checkOut,
    persons_quantity,
    nights,
    AccommodationId
  ) => {
    window.dataLayer.push({
      event: 'property_click',
      ecommerce: {
        detail: {
          products: {
            language: navigator.language,
            city: city,
            days_quantity: nights,
            valid_till: check_in,
            valid_from: checkOut,
            persons_quantity: persons_quantity,
            hotel_id: AccommodationId,
          },
        },
      },
    });
  };
