import React, { useEffect } from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import Footer from "../footer/Footer";
import Topbar from "../Topbar/Topbar";

export const Booking = () => {
  const [user, setUser] = useState("");
  const [hoteldetails, sethoteldetails] = useState("");
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const userDetails = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
      
    if (userDetails) {
      console.log(userDetails.result.result.data[0].profile_id[0]);
      fetch("/api/bookings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postProfileId: userDetails.result.result.data[0].profile_id[0],
        }),
      })
        .then((res) => res.json())
        .then((pastBooking) => {
          console.log(pastBooking);
          sethoteldetails(pastBooking.getHotels.Success[0].Result[0]);
          console.log(hoteldetails);
          setloading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("no users found");
    }
  }, []);
  return (
    <>
      <Topbar color="#FF3D00" page="property" user={user} setUser={setUser} />

      <div className="container">
        <div className="row">
          <h3 className=" main-heading  mt-5 text-orange">My Booking</h3>

          <div className="flex-column">
            <div className="col-md-12 m-0 p-0">
              {loading ? (
                <img
                  className="bookingimg"
                  src={hoteldetails.ImageURL}
                  alt=""
                />
              ) : (
                <Skeleton />
              )}

              {loading ? (
                <button className="btnstatus card-img-overlay" type="button">
                  {hoteldetails.BookingStatus[0]}
                </button>
              ) : (
                <Skeleton />
              )}
              {loading ? (
                <p>
                  <span className="main-heading">
                    {hoteldetails.AccommodationName[0]}
                  </span>
                </p>
              ) : (
                <Skeleton />
              )}
              {loading ? (
                <p className="mb-1">
                  <span className="main-heading">
                    {Array.apply(null, {
                      length: hoteldetails.Rating[0]
                        ? Number(hoteldetails.Rating[0])
                        : 0,
                    })
                      .map(Number.call, Number)
                      .map((item) => {
                        return (
                          <img
                            key={item}
                            src={
                              process.env.PUBLIC_URL + "/images/Asset100.svg"
                            }
                            className="bookingstar"
                            alt=""
                          />
                        );
                      })}{" "}
                  </span>
                </p>
              ) : (
                <Skeleton />
              )}

              {loading ? (
                <p>
                  <span className="bookingtext">Booking from : </span>
                  <i className="text-orange sub-heading">
                    {hoteldetails.ArrivalDate[0]}
                  </i>
                  <span className="bookingtext"> to : </span>
                  <i className="text-orange sub-heading">
                    {hoteldetails.DepartureDate[0]}
                  </i>
                </p>
              ) : (
                <Skeleton />
              )}

              {loading ? (
                <p className="mb-1">
                  <b className="text-orange bookingtext">Price: </b>
                  {hoteldetails.TotalPrice[0]}
                </p>
              ) : (
                <Skeleton />
              )}
              {loading ? (
                <p className="mb-1">
                  <b className="text-orange bookingtext">Booking ID: </b>
                  {hoteldetails.BookingID[0]}
                </p>
              ) : (
                <Skeleton />
              )}
              {loading ? (
                <p className="mb-1">
                  <b className="text-orange bookingtext">Pin Code: </b>
                  {hoteldetails.PinCode[0]}
                </p>
              ) : (
                <Skeleton />
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};


