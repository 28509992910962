import React, {useState}from 'react'
import Topbar from "../Topbar/Topbar";
import Footer from "../footer/Footer";

function BlankPage({location}) {
    const [user, setUser] = useState('');

    return (
        <section className='vh-100 d-flex flex-column justify-content-between'>
          <Topbar color='#FF3D00' page='property' user={user} setUser={setUser}/>
            <div className="blank-content">
                <h1 className="text-center main-heading mb-4 mt-5 slow go bounceIn">
                {location.state.content}
                <i class="bi bi-emoji-frown-fill mx-3 no-deals"></i>
                </h1>
            </div>
            <Footer/>
        </section>

    )
}

export default BlankPage
