import React from 'react'
import { Link } from 'react-router-dom'

function Calltoaction() {
  return (
    <section className='bg-dark'>
      <div className="container py-4">
        <div className="text-center z-index-0">
          <div className="row px-lg-5">
            <div className="col-12 px-lg-5">
              <h1 className="main-heading text-orange">Become a Host</h1>
              <p className="calltoaction-text text-white mx-lg-5 f-12 mx-2">
                Picture your property as the aesthetically pleasing and generate more revenue.<br/> Find that appealing? Then signing up as a host with Orangeloft might be the right decision for you!</p>
            </div>
            <div className="col-lg-6 col-12 mx-auto mt-3 mb-1">
              <Link to='/add-Property' className="btn-primary btn">
                Register Now
              </Link>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Calltoaction

