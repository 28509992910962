import React, { useState } from 'react'
import Footer from '../footer/Footer'
import Topbar from '../Topbar/Topbar'

const About = () => {
  const [user, setUser] = useState('');

  return (

    <>
      <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
      <section className="hero_section_terms" id="section">
        <div className="container h-100">
          <div className="hero_inner h-100">
            <div className="row mx-auto text-center selection-h mb-3">
              <h1 className="main-heading f-25 fadeInDown slow go text-white underliene">
                We Host Better!
              </h1>
            </div>
          </div>
        </div>

      </section>
      <section className='container-fluid py-3 discription-terms-container my-5'>
        <div className="container mb-5">
          <div class='AboutusText'>
            <p>
              Orange Loft is a pioneering hospitality brand up-North that guarantees tourists (from across the Nation and Worldwide), an unforgettable stay in all the Cultural/Scenic landmarks of Pakistan. With a Mission to Reinvent Hospitality – from Cityscape to Landscape – Orange Loft offers standardized and hassle-free lodgings for its guests that allow them to truly relish a stay at their favorite spot!
            </p>
          </div>
        </div>

      </section>

      <Footer />


    </>
  )


}
export default About