import { useState, useEffect } from 'react'
import './Style.css'
import React from "react";
import Topbar from '../Topbar/Topbar';
import Footer from '../footer/Footer';
import { right } from '@popperjs/core';
import history from '../history';
import Skeleton from 'react-loading-skeleton';
import swal from 'sweetalert';

export const Head = (props) => {
    return (
        <div className={`box-shadow-2 align-items-center justify-content-center  headtitle   mb-0 ${props.val === false ? "d-none" : ""} ${props.hom2 === false ? "d-none" : ""}`}>
            <div className='mb-2'>
                <h4 className='third-heading my-3 text-center f-15'> Ambassador Porgram</h4>
            </div>
        </div>)
}

export const FormContainer = ({ prog }) => {
    const handleChange = (event) => {
        event.preventDefault();
        setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
    };
    const [contactInfo, setContactInfo] = useState({
        fullname: "",
        email: '',
        phone: "",
        city: "",
        Occupation: "",
        source: '',
        Institute: '',
        bwUserid: JSON.parse(localStorage.getItem('user')).result.result.data[0].profile_id[0]
    });

    const [steps, setSeteps] = useState(0);
    const [sucessMsg, setsucessMsg] = useState(false);


    const handleSubmit = (event) => {
        event.preventDefault();
        if (steps == 2) {
            console.log(contactInfo);
            fetch('/api/createAmbassador', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contactInfo),
            }).then(createAbRes => createAbRes.json()).then((createAbResModify) => {
                console.log(createAbResModify);
                if (createAbResModify.Status == "success") {
                    console.log("in")
                    setsucessMsg(true)

                } else if (createAbResModify.msg) {
                    swal({
                        // title: '<i class="bi bi-emoji-frown-fill"></i>',
                        icon: "warning",
                        text: `${createAbResModify.msg}`,
                        timer: 2500,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                }

            });
        }
    }

    return (
        <>
            {sucessMsg ? <Thanks /> :
                <div className="pt-0">
                    <Head />
                    <div className='container FormContainer py-lg-5 px-lg-3'>
                        <div className={"container w-100 px-3 "} >
                            <progress className={`w-100  progre`} value={prog} max={100} >30%</progress>
                        </div>
                        <div className={`align-items-center justify-content-center flex-column`}>
                            <div className='w-100 mb-2' >
                                <h2 className="mb-0 main-heading text-center">Become an Ambassador</h2>
                                <p className='he pb-2'>Please register to become an ambassador</p>
                            </div>
                        </div>
                        <form className='px-lg-5 py-lg-4' onSubmit={handleSubmit}>
                            {steps == 0 ?
                                <>
                                    <div className='mb-2'>
                                        <input type="text" class="form-control form-control-md mb-3" name='fullname' value={contactInfo.fullname} onChange={handleChange} placeholder="Enter Name" required></input>
                                    </div>
                                    <div className='mb-2'>
                                        <input type="email" class="form-control form-control-md mb-3" name='email' value={contactInfo.email} onChange={handleChange} placeholder="Enter Email" required></input>
                                    </div>
                                    <div className='mb-2'>
                                        <input type="text" class="form-control form-control-md mb-3" name='phone' value={contactInfo.phone} onChange={handleChange} placeholder="Enter Phone Number" required></input>
                                    </div>
                                </> :
                                steps == 1 ?
                                    <>
                                        <div className='mb-2'>
                                            <input type="text" class="form-control form-control-md mb-3" value={contactInfo.Occupation} onChange={handleChange} name='Occupation' placeholder="Enter Occupation"></input>
                                        </div>
                                        <div className='mb-2'>
                                            <input type="email" class="form-control form-control-md mb-3" value={contactInfo.city} onChange={handleChange} name='city' placeholder="Enter City"></input>
                                        </div>
                                        <div className='mb-2'>
                                            <input type="email" class="form-control form-control-md mb-3" value={contactInfo.source} onChange={handleChange} name='source' placeholder="How did you get to know about our Ambassador Program?" />
                                        </div>
                                    </>
                                    : steps == 2 ?
                                        <>
                                            <div className='mb-2'>
                                                <input type="email" class="form-control form-control-md mb-3" value={contactInfo.Institute} onChange={handleChange} name='Institute' placeholder="Enter Institute Name"></input>
                                            </div>
                                        </>

                                        : null
                            }
                            <div className='container'>
                                <div className='row '>
                                    <div className=' col-sm-12 col-sm-12 col-lg-12 d-flex align-items-center justify-content-end'>
                                        {steps < 2 ?
                                            <>
                                                {steps !== 0 ? <button type='button' className='btn-topbar-primary-outline mx-3' onClick={() => setSeteps(steps > 0 ? steps - 1 : 0)}>Previous</button> : null}
                                                <button type='submit' className='btn-topbar-primary' onClick={() => setSeteps(steps + 1)}>Next </button>
                                            </>
                                            : steps == 2 ? <button type='submit' className='btn-topbar-primary' onClick={handleSubmit}>Submit</button> : null

                                        }
                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            }

        </>

    )
}

export const Thanks = () => {
    const [ambassador, setambassador] = useState('')
    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
            const foundUser = JSON.parse(localStorage.getItem("user"));
            fetch('/api/check_ambassador', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ profile_id: foundUser.result.result.data[0].profile_id }),
            }).then(check => check.json()).then((check1) => {
                console.log(check1)
                setambassador(check1.Ambassador)
            });
        }
    }, []);
    return (
        <section className='m-0'>
            <div class="lasts">
                <div className='container'>
                    <div class="d-flex justify-content-even align-items-center">
                        <div class=" col-sm-8 col-md-10  textcircle">
                            <h4 class="h5 text-white" ><b>Hello, {JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).result.result.data[0].fname : ''}!</b></h4>
                            <p className='pproo' >Welcome to your Ambassador Profile</p>
                        </div>
                        <div className='percentage-container textcircle'>{ambassador ? ambassador.Collection.Rows[0].commission_percentage : 0}%</div>
                    </div>

                </div>
                <div className='container'>

                    <div class="row p-0 m-0">
                        <div class=" d-flex  circl" >
                            <div class=" col-sm-3 col-md-3 col-lg-3 m-0 p-0 circlfloat">
                                <div class="circles " ><p className='textc '>{ambassador ? ambassador.Collection.CompletedBookings : 0}</p></div>
                                <p className='txt2 p-0 circlfloat'>Bookings Completed</p></div>
                            <div class=" col-sm-3 col-md-3 col-lg-3 m-0 p-0  circlfloat">

                                <div class="circles2 " ><p className='textc'>{ambassador ? ambassador.Collection.PipelineBookings : 0}</p></div>
                                <p className='txt2 p-0 circlfloat'> Bookings in Pipeline</p></div>
                            <div class=" col-sm-3 col-md-3 col-lg-3 m-0 p-0 circlfloat">

                                <div class="circles " ><p className='textc'>{ambassador ? ambassador.Collection.Rows[0].total_commission !== null ? ambassador.Collection.Rows[0].total_commission : 0 : 0}</p></div>
                                <p className='txt2 p-0 circlfloat'>Commission Earned</p></div>
                            <div class=" col-sm-3 col-md-3 col-lg-3 m-0 p-0 circlfloat ">

                                <div class="circles2"><p className='textc'>{ambassador ? ambassador.Collection.CancelledBookings : 0}</p></div>
                                <p className='txt2 p-0  circlfloat'>Bookings Cancelled</p></div>

                        </div></div></div></div>


            <div className='container'>
                <div className='row '>
                    <div className=' col-sm-12 col-sm-12 col-lg-12 d-flex mt-3'>
                        <button type="button" className='btn-primary-seach'> Code: {ambassador ? ambassador.Collection.Rows[0].code : 0} </button>

                    </div>
                </div>
            </div>

            {/* <div className='container'>
                <div className='row '>

                    <div className=' col-sm-12 col-sm-12 col-lg-12 d-flex mt-3'>
                        <a href='' className='ltext1 col-sm-6 col-sm-6 col-lg-6 '><p style={{ textDecoration: "underline" }}>Add Payment Method</p></a>
                        <a href='' className='ltext2  col-sm-6 flex-item-right col-sm-6 col-lg-6 '><p style={{ textDecoration: "underline" }}>More Information</p></a>


                    </div>
                </div>
            </div> */}

        </section>

    )
}

export const Embessider = () => {



    //Array Of Values

    const Arra = [
        {
            progress: 30,
            titl1: " Enter Name",
            titl2: "Enter Email",
            titl3: "Enter Phone Number",
        },
        {
            progress: 70,
            titl1: "Enter Occupation",
            titl2: "Enter City",
            titl3: "How did you get to know about our Ambassador Program?",
        },

        {}

    ];

    const [sets, setsstate] = useState(0);
    const [set1, setstate1] = useState(0);
    const [user, setUser] = useState('');
    const [ambassadorStatus, setambassadorStatus] = useState('loading');
    useEffect(() => {
        const foundUser = JSON.parse(localStorage.getItem("user"));
        console.log(foundUser)
        if (foundUser) {
            fetch('/api/check_ambassador', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ profile_id: foundUser.result.result.data[0].profile_id }),
            }).then(check => check.json()).then((check1) => {
                console.log(check1)
                setambassadorStatus(check1.Ambassador.Status);
            });
        } else {
            history.push({
                pathname: '/'
            })
        }
    }, [user]);



    return (
        <section className='vh-100 d-flex flex-column justify-content-between'>
            <section>
                <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
                {
                    ambassadorStatus === "loading" ?
                        <Skeleton /> :
                        ambassadorStatus == "failure" ?
                            <FormContainer
                                prog={Arra[sets].progress}
                                setambassadorStatus={setambassadorStatus}
                            />
                            : <Thanks />
                }

            </section>

            <Footer />
        </section>
    )

}
