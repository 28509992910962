import React, { useState, useEffect } from 'react'
import Button from '../BasicComponents/Button';
import { isMobile } from 'react-device-detect';
import { FiSearch } from "react-icons/fi";
import AutoSearchFromApi from '../home/search/AutoSearchFromApi';
import swal from "sweetalert";
import moment from "moment";
import history from "../history";

function Searchheader(props) {
  const [price, setPrice] = useState('');
  const [state, setState] = useState([
    {
      startDate: new Date(props.checkIn),
      endDate: new Date(props.checkOut),
      key: 'selection'
    }
  ]);
  const [show, setshow] = useState(false);
  const [rating, setrating] = useState('');
  const [star, setstar] = useState('');


  async function sort(sortby, sort, name) {
    props.setStateOfParentloading();
    var resultArray = props.properties;
    if (name == 'price') {
      if (price == '' || price == 1) {
        setPrice('2');
      } else {
        setPrice('1');
      }
      setrating('');
      setstar('');
    }
    if (name == 'top') {
      setrating('');
      setstar('');
      setPrice('');

    }
    if (name == 'rating') {
      if (rating == '' || rating == 1) {
        setrating('2');
      } else {
        setrating('1');
      }
      setPrice('');
      setstar('');
    }
    if (name == 'star') {
      if (star == '' || star == 1) {
        setstar('2');
      } else {
        setstar('1');
      }
      setPrice('');
      setrating('');
    }
    if (sortby == 5) {
      if (sort == 0) {
        await resultArray.sort(function (a, b) {
          return b.UserRating[0] - a.UserRating[0];
        });
      } else {
        await resultArray.sort(function (a, b) {
          return a.UserRating[0] - b.UserRating[0];
        });
      }
    } else if (sortby == 4) {
      if (sort == 0) {
        await resultArray.sort(function (a, b) {
          return b.MinRate[0] - a.MinRate[0];
        });
      } else {
        await resultArray.sort(function (a, b) {
          return a.MinRate[0] - b.MinRate[0];
        });
      }
    } else if (sortby == 2) {
      if (sort == 0) {
        await resultArray.sort(function (a, b) {
          return b.Rating[0] - a.Rating[0];
        });
      } else {
        await resultArray.sort(function (a, b) {
          return a.Rating[0] - b.Rating[0];
        });
      }
    }


    setTimeout(() => {
      if (name == "top") {
        resultArray = props.propertiesCopy;

      } else {
        resultArray = resultArray.filter(a => a.MinRate[0] !== "");

      }
      props.setStateOfParent(resultArray);
      props.setStateOfParentloading();

    }, 500)
  }

  const onFocus = () => {
    setshow(true)
  }

  const handleOnSelect = (result) => {
    if (result.searchQuery == "") {
      swal({
        text: "Please select destination!",
        icon: "warning",
        buttons: false,
      });
    } else {
      if (!result.AccommodationId) {
        history.push('/')
        history.push(
          `/destination&city=${result.CityName}&check_in=${moment(state[0].startDate).format("YYYY-MM-D")}&check_out=${moment(state[0].endDate).format("YYYY-MM-D")}&adult=2&rooms=1&nights=2`
        );
        props.handleCityChange(result.CityName)
        setshow(false)

      } else {
        history.push(
          `/hotels&city=${result.CityName}&check_in=${moment(state[0].startDate).format("YYYY-MM-D")}&check_out=${moment(state[0].endDate).format("YYYY-MM-D")}&adult=2&rooms=1&nights=2&hotel_id=${result.AccommodationId}`
        );
      }
    }
  };

  return (
    <section className={!isMobile ? 'searchheaderBox' : ''}>
      <div className="container-2 pp-3 d-md-flex d-lg-flex justify-content-between align-items-center">
        <div className="search-input-container">
          <div className="input-div">
            <AutoSearchFromApi
              onFocus={onFocus}
              onSearch={onFocus}
              placeholder={props.city}
              height='35px'
              handleOnSelect={handleOnSelect}
            />
          </div>

          <div className='search-icon1'>
            <FiSearch className='searc-i' />
          </div>
          {/* <div className='search-icon2'>
            <img src='images/map1.png' className='w-100 h-100'></img>
          </div> */}

        </div>
        {!isMobile ? <div className='d-flex alignitems-center justify-content-center'>
          <div className='ml-1'>
            <Button childern="Top Picks" type="button" buttonSize="btn-medium" buttonStyle="btn-topbar-primary" classname={(price || rating || star) ? "asc" : ''} onClick={() => sort(4, 0, "top")}></Button>
          </div>
          <div className='ml-1'>
            <Button childern="Price" type="button" buttonSize="btn-medium" buttonStyle="btn-topBar-secondary" classname={price == "1" ? 'down-price' : price == "2" ? 'up-price' : ''} onClick={() => { price == '' || price == "1" ? sort(4, 0, "price") : sort(4, 1, "price") }}></Button>
          </div>
          <div className='ml-1'>
            <Button childern="Rating" type="button" buttonSize="btn-medium" buttonStyle="btn-topBar-secondary" classname={rating == "1" ? 'down-price' : rating == "2" ? 'up-price' : ''} onClick={() => { rating == '' || rating == "1" ? sort(5, 0, "rating") : sort(5, 1, "rating") }}></Button>
          </div>
          <div className='ml-1'>
            <Button childern="Stars" type="button" buttonSize="btn-medium" buttonStyle="btn-topBar-secondary" classname={star == "1" ? 'down-price' : star == "2" ? 'up-price' : ''} onClick={() => { star == '' || star == "1" ? sort(2, 0, "star") : sort(2, 1, "star") }}></Button>
          </div>
          {/* <Link className='ml-2 cursor' target="_blank" to={`/Roomph-map&city=${query}&check_in=${checkdates.startDate ? checkdates.startDate : props.checkIn}&check_out=${checkdates.endDate ? checkdates.endDate : props.checkOut}&adult=${props.adults}&rooms=${props.rooms}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30.43" height="27.937" viewBox="0 0 31.43 27.937">
              <path fill='#FF3D00' d="M15.715,0A6.875,6.875,0,0,0,8.84,6.875c0,3.07,4.493,8.665,6.215,10.7a.861.861,0,0,0,1.32,0C18.1,15.54,22.59,9.945,22.59,6.875A6.875,6.875,0,0,0,15.715,0Zm0,9.167a2.292,2.292,0,1,1,2.292-2.292A2.291,2.291,0,0,1,15.715,9.167ZM1.1,11.783A1.746,1.746,0,0,0,0,13.4V27.063a.873.873,0,0,0,1.2.811L8.73,24.445V11.727a16.524,16.524,0,0,1-1.16-2.533Zm14.617,7.842a2.607,2.607,0,0,1-1.992-.925c-1.073-1.266-2.214-2.708-3.246-4.186v9.931l10.477,3.492V14.514c-1.032,1.478-2.173,2.92-3.246,4.186A2.608,2.608,0,0,1,15.715,19.625ZM30.232,8.794,22.7,12.223V27.937l7.633-3.053a1.746,1.746,0,0,0,1.1-1.621V9.6A.873.873,0,0,0,30.232,8.794Z"></path>
            </svg>
          </Link> */}

        </div> : null}

      </div>
      <div className="searchbox-backdrop position-fixed w-100 h-100" style={{ display: show ? 'block' : 'none' }} onClick={() => { setshow(false) }}></div>

    </section>
  )
}

export default Searchheader