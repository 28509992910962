import React from 'react'
import './Faqs.css';
import Faq from "react-faq-component";

const data = {
    rows: [
        {
            title: "How do I make a hotel booking on Orangeloft.pk?",
            content: `To make a booking, select your travel dates using the calendar and destination/hotel search functions. Read hotel descriptions and reviews from other travelers. Check rates and availability then enter booking details like number of guests to complete the reservation process. You can pay securely online or arrange payment at the hotel.`,
        },
        {
            title: "What payment methods do you accept?",
            content:"For online payments during booking, we accept all major credit cards including Visa, Mastercard, and American Express. We also accept payment by bank transfer for reservations. At check-in, guests can pay by cash in local currency or credit card upon presentation of a valid ID.",
        },
        {
            title: "Can I cancel or change my booking?",
            content: `Yes, you can modify or cancel most bookings depending on the hotel's policy. We recommend checking individual cancellation policies during booking. For changes, contact the hotel directly. For cancellations within the penalty period, you may incur cancellation fees according to the hotel's policy.`,
        },
        {
            title: "Can I book hotels last minute?",
            content:"Yes, we have availability for same-day or next-day bookings depending on the property. Please call our support team to check for short-notice reservations. Rates may be higher for late bookings.",
        },
        {
            title: "What is your cancellation policy?",
            content:"Most hotels allow cancellations without penalty up to 24-48 hours before check-in time. You will be notified of exact deadlines during booking. Failure to cancel in time or no-shows will result in charging the full price of the non-refundable first night to your card.",
        },
    ],
};
const styles = {
    bgColor: 'transparent',
    titleTextColor: "var(--main-theme-color)",
    rowTitleColor: "var(--main-theme-color)",
    // rowContentColor: 'grey',
    arrowColor: "var(--main-theme-color)",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    openOnload: 0,
    // expandIcon: "+",
    // collapseIcon: "-",
};
const Faqs = () => {
  return (
    <section className='mtt-3 mbb-3'>
    <div className='container'>
      <h5 className="text-center main-heading mt-4 mb-2">FAQ</h5>
      <Faq
                data={data}
                styles={styles}
                config={config}
            />
    </div>
  </section>
  )
}

export default Faqs
