
import React, { Component } from 'react'
import swal from 'sweetalert';
import history  from '../history';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
                  swal({
                // title: '<i class="bi bi-emoji-frown-fill"></i>',
                icon: "warning",
                text: 'Oh noez! could not fetch the data for that resorce.',
                timer: 2500,
                showCancelButton: false,
                showConfirmButton: false,
                buttons: false
              }).then(
                function () {
                    history.push({
                        pathname: "/"
                    })
                },
                // handling the promise rejection
                function (dismiss) {
                  if (dismiss === 'timer') {
                    history.push({
                        pathname: "/"
                    })
                  }
                }
              )
            // You can render any custom fallback UI
            // return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}


