import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Footer from '../footer/Footer'
import Topbar from '../Topbar/Topbar'
import {MdLocationOn} from "react-icons/md";
import { fetch_blogs } from '../../server';

function blogs() {
  const [Blogs, setblogs] = useState([]);
  const [user, setUser] = useState('');
  const fetchApiData = async () => {
    const data = await fetch_blogs();
    setblogs(data.Collection.Rows)
  };
  useEffect(() => {
    fetchApiData();
  }, []);
  


  return (
    <section className='vh-100 d-flex flex-column justify-content-between'>
      <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
      <div className='container px-sm-3 py-lg-3 py-5'>
      <h5 className="text-center main-heading mb-4 text-capitalize">Unleash the <span className='main-heading text-orange'>traveler</span> inside you</h5>
       <div className="row">
       {Blogs.map((blog, index) => {
            return (

              <div className="blogs-outer col-lg-4 col-md-4 col-6" key={index}>
              <div className="blog-card box-shadow-2" >
                  <Link to={{
                      pathname: "/singleblog",
                      state: { blog }
                  }} className="link">
                      <div className="blog-card-inner">
                          <img className="blog-img box-shadow-2" src={`https://roomph.ascendant.travel/${blog.Image}`} alt="Avatar" />
                          <div className="mx-3 my-3 text-center">
                              <div className='link px-lg-8'>{blog.Name.replace(/<(.|\n)*?>/g, '')}</div>
                              {/* <span className='blog-discription'><span><MdLocationOn className='f-13'/></span>{blog.Location?blog.Location.replace(/<(.|\n)*?>/g, ''):'Abbottabad Lake, Hunza'}</span> */}
                              <Link to={{
                                  pathname: "/singleblog",
                                  state: { blog }
                              }} className='blog-discription'>Read More...</Link>

                          </div>

                      </div>
                  </Link>

              </div>
          </div>
           

            )

        })}
       </div>
       
      </div>
      <Footer />

    </section >

  )
}

export default blogs

