import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
} from "react-lightgallery";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/thumbs/thumbs.min.css';
import "lightgallery.js/dist/css/lightgallery.css";
import Skeleton from "react-loading-skeleton";

// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";

export function PropertyImageSliderLoading() {
    return (
        <div>
            <div className="mySwiper2_skelton">
                <Skeleton className="mySwiper2_skelton" />
            </div>
            <div className="row">
                <div className="col-3 mySwiper">
                    <Skeleton className="h-100 w-100" />
                </div>
                <div className="col-3 mySwiper">
                    <Skeleton className="h-100 w-100" />
                </div>
                <div className="col-3  mySwiper">
                    <Skeleton className="h-100 w-100" />
                </div>
                <div className="col-3  mySwiper">
                    <Skeleton className="h-100 w-100" />
                </div>
            </div>
        </div>
    )
}



const OpenButtonWithHoc = withLightgallery(({ openGallery, ...props }) => {
    return (
        <button
            {...props}
            onClick={() => {
                console.clear()
                openGallery("group1");
            }}
            className={["button is-primary z-index-2", props.className || ""].join(" ")}
        >
            View all photos
        </button>
    );
});


function PropertyImageSlider({ Images }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <section className="mx-lg-1 mx-3 position-relative">
            <LightgalleryProvider>
                <div className="image-container-re position-relative">
                    <Swiper
                        style={{
                            "--swiper-navigation-color": "#fff",
                            "--swiper-pagination-color": "#fff",
                        }}
                        loop={true}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                    >
                        {
                            Images.map((img, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <LightgalleryItem group="group1" src={img.$.Image_URL} thumb={img.$.Image_URL}>
                                            <img src={img.$.Image_URL} />
                                        </LightgalleryItem>
                                    </SwiperSlide>)
                            })
                        }
                    </Swiper>

                </div>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                >
                    {
                        Images.map((img, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img src={img.$.Image_URL} />
                                </SwiperSlide>
                            )
                        })
                    }

                </Swiper>
                <OpenButtonWithHoc />
            </LightgalleryProvider>

        </section>
    )
}
export function RoomImageSlider({ imgSrc, feturedImage, RoomName }) {

    return (
        <section className="position-relative">
            {/* <span className="roomname">{RoomName}</span> */}

            <LightgalleryProvider>
                <div className="room-image-container-re position-relative">
                    <Swiper
                        style={{
                            "--swiper-navigation-color": "#fff",
                            "--swiper-pagination-color": "#fff",
                        }}
                        loop={false}
                        spaceBetween={10}
                        navigation={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="room_mySwiper2"
                    >
                        {
                            imgSrc ?
                                imgSrc.map((img, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <LightgalleryItem group="group1" src={img.$.Photo_Max500} thumb={img.$.Photo_Max500}>
                                                <img src={img.$.Photo_Max500} />
                                            </LightgalleryItem>
                                        </SwiperSlide>)
                                }) :
                                <SwiperSlide>
                                    <LightgalleryItem group="group1" src={feturedImage} thumb={feturedImage}>
                                        <img src={feturedImage} />
                                    </LightgalleryItem>
                                </SwiperSlide>
                        }
                    </Swiper>

                </div>
            </LightgalleryProvider>



        </section>
    )
}

export default PropertyImageSlider