import React, { useState } from "react";
import Footer from "../footer/Footer";
import Topbar from "../Topbar/Topbar";
import CustomCheckBox from "../BasicComponents/CustomCheckBox";
import FormInput from "../BasicComponents/FormInput";
import { useHistory } from "react-router";
import SweetAlert2 from "react-sweetalert2";
import { add_hotel } from "../../server";

function Property() {
  const history = useHistory();
  const [swalProps, setSwalProps] = useState({});

  function handleClick() {
    setSwalProps({
      show: true,
      title: "Example",
    });
  }
  const [hotelinfo, sethotelinfo] = useState({
    fullname: "",
    Address: "",
    Type: "",
    NoRooms: "",
    city: "",
    contactPerson: "",
    email: "",
    Mobile: "",
    dateTime: "31",
    status: "dd",
  });
  const hoteldata = (e) => {
    sethotelinfo({ ...hotelinfo, [e.target.name]: e.target.value });
  };

  const Array = [
    {
      id: 0,
      name: "fullname",
      type: "text",
      placeholder: "Property Name",
      errorMessage: "Property Name is Required",
      label: "",
      required: true,
    },
    {
      id: 1,
      name: "Address",
      type: "text",
      placeholder: "Property Address",
      errorMessage: "Property Address is Required",
      label: "",
      required: true,
    },
    {
      id: 2,
      name: "NoRooms",
      type: "text",
      placeholder: "Number of Rooms",
      errorMessage: "Number of Rooms is required",
      label: "",
      required: true,
    },
    {
      id: 3,
      name: "city",
      type: "text",
      placeholder: "City",
      errorMessage: "City is required",
      label: "",
      required: true,
    },
    {
      id: 4,
      name: "contactPerson",
      type: "text",
      placeholder: "Contact person",
      errorMessage: "Contact person is Required",
      label: "",
      required: true,
    },
    {
      id: 5,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Email is Required",
      label: "",
      required: true,
    },
    {
      id: 6,
      name: "Mobile",
      type: "phone",
      placeholder: "Contact Number",
      errorMessage: "Contact Number is Required",
      label: "",
      required: true,
    },
  ];

  const [user, setUser] = useState("");

  // const [Formerrors,seterror]=useState('')

  const hotelsubmit = async (event) => {
    // seterror(validate(hotelInfo));
    event.preventDefault();

    console.log(hotelinfo);
    const data = await add_hotel({
      fullname: hotelinfo.fullname,
      Address: hotelinfo.Address,
      Type: hotelinfo.Type,
      NoRooms: hotelinfo.NoRooms,
      city: hotelinfo.city,
      contactPerson: hotelinfo.contactPerson,
      email: hotelinfo.email,
      Mobile: hotelinfo.Mobile,
      dateTime: "20-09-2022",
      status: "1",
    });
    fetch("form.pdf").then((response) => {
      console.log(response);
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Orangeloft_form.pdf";
        alink.click();
      });
    });
    setTimeout(() => {
      setSwalProps({
        show: true,
        icon: "success",
        text: "The property hosting form has been successfully downloaded. Please fill it out and submit it as a scanned copy to orangeloftpk@gmail.com.",
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      <SweetAlert2
        {...swalProps}
        didClose={() => {
          // run when swal is closed...
          history.go(0);
        }}
      ></SweetAlert2>
      <Topbar color="#FF3D00" page="property" user={user} setUser={setUser} />
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-12">
            <h3 className="main-heading ft-20 text-orange text-center mb-4">
              Why register with Orangeloft!
            </h3>
            <section>
              <p>
                <strong>
                  <u>Here's why you should sign up with us:</u>
                </strong>
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <p>
                <strong>-Brand security</strong>
              </p>
              <p>
                Orangeloft is a well known brand operating all over the country,
                and the attraction that comes with being part of such a platform
                should not be missed!
              </p>
              <p>&nbsp;</p>
              <p>
                -<strong>Make it a happening place</strong>
              </p>
              <p>
                We extend our support to turn your place into state of art site
                with all the unique renovations done, according to our
                standards.
              </p>
              <p>&nbsp;</p>
              <p>
                -<strong>Ensure Better customer Experience</strong>
              </p>
              <p>
                We make the standard bars and train staff to ensure our quality
                remains constant; you provide the best service for your client!
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>-Diversified client profile </strong>
              </p>
              <p>
                Orangeloft provides you a diversified client profile ranging
                from national to international travelers, adventures and
                explorers through the year!
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>-Expert advice on board</strong>
              </p>
              <p>
                Our team of professionals guarantees the best profiteering
                policies and advice for you, just one contact away!
              </p>
              <p>&nbsp;</p>
              <p>
                -<strong>More Reservations and bookings.</strong>
              </p>
              <p>
                Generate more bookings using our extensive bookings platform as
                we will list you on various OTAs, our website and mobile
                application. Hence, generating more revenue for you!
              </p>
              <p>&nbsp;</p>
              <p>
                -<strong>Marketing and Technical Support</strong>
              </p>
              <p>
                We will market your property using modes and methods which deems
                fit and also promote it around the global!
              </p>
              <p>&nbsp;</p>
              <p>
                If more earnings, standardized operating policies, and a well
                organized system suited to your needs is what you want, then
                collaborating with us might be the right place for you!
              </p>
            </section>
          </div>

          <div className="col-lg-5 col-md-5 col-12 pt-lg-0 pt-4">
            <h3 className="main-heading ft-20 text-orange text-center mb-4">
              Register Your Property
            </h3>
            <div className="border border-color-orange border-radius px-3 py-4 ryp bg-white">
              <h6 className="third-heading text-uppercase">
                Property information
              </h6>

              <form onSubmit={hotelsubmit} className="row p-0 m-0">
                {Array.map((values) => (
                  <div className="col-12 mt-3" key={values.id}>
                    <FormInput
                      {...values}
                      label={values.label}
                      value={hotelinfo[values.name]}
                      onChange={hoteldata}
                      required={values.required}
                    />
                  </div>
                ))}

                <div className="col-12 mt-3">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    value={hotelinfo.Type}
                    name="Type"
                    onChange={hoteldata}
                  >
                    <option selected>Select type</option>
                    <option value="hotel">Hotel</option>
                    <option value="apartment">Apartment</option>
                    <option value="hostel">Hostel</option>
                    <option value="resort">Resort</option>
                    <option value="motel">Motel</option>
                    <option value="bed_and_breakfast">Bed and Breakfast</option>
                    <option value="guest_house">Guest house</option>
                    <option value="campground">Campground</option>
                    <option value="capsule_hotel">Capsule Hotel</option>
                    <option value="farm_stay">Farm stay</option>
                    <option value="holiday_park">Holiday Park</option>
                    <option value="inn">Inn</option>
                    <option value="lodge">Lodge</option>
                    <option value="self_catering_vacation_rentals">
                      Self-Catering Vacation Rentals
                    </option>
                    <option value="vacation_rental_self-catering">
                      Vacation Rental (self-catering)
                    </option>
                    <option value="private_room_in_house">
                      Private room in house
                    </option>
                  </select>
                </div>

                <div className="p-0 border-0 my-3 bg-white">
                  <CustomCheckBox
                    value="1"
                    name="premium"
                    id="roomphpremium"
                    label="I agree with the terms and conditions of Orangeloft!"
                    className="ml-2"
                  />
                </div>
                <div className="p-0 border-0 text-center">
                  <button
                    type="submit"
                    className="bg-orange btn-primary btn-large d-inline-block mx-auto px-3 py-2 text-white text-center mt-3  ft-20"
                  >
                    Download the form
                  </button>
                </div>
              </form>
              {/* <form action="" method="post" onsubmit={hotelsubmit}>
                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">Property Name: *</label>
                  <input
                    type="text"
                    value={hotelInfo.fullname}
                    onChange={Addhotel}
                    placeholder=""
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="fullname"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">
                    Property Address: *
                  </label>
                  <input
                    type="text"
                    value={hotelInfo.Address}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="Address"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">
                    Property Type
                    <span className="ft-12">
                      (e.g. Guest House, Hotel, Motel etc):
                    </span>
                    *
                  </label>
                  <select
                    name="Type"
                    value={hotelInfo.Type}
                    className=" ft-18 w-100 border border-radius p-2 "
                    onChange={Addhotel}
                  >
                    <option value="">Select type</option>
                    <option value="hotel">Hotel</option>
                    <option value="apartment">Apartment</option>
                    <option value="hostel">Hostel</option>
                    <option value="resort">Resort</option>
                    <option value="motel">Motel</option>
                    <option value="bed_and_breakfast">Bed and Breakfast</option>
                    <option value="guest_house">Guest house</option>
                    <option value="campground">Campground</option>
                    <option value="capsule_hotel">Capsule Hotel</option>
                    <option value="farm_stay">Farm stay</option>
                    <option value="holiday_park">Holiday Park</option>
                    <option value="inn">Inn</option>
                    <option value="lodge">Lodge</option>
                    <option value="self_catering_vacation_rentals">
                      Self-Catering Vacation Rentals
                    </option>
                    <option value="vacation_rental_self-catering">
                      Vacation Rental (self-catering)
                    </option>
                    <option value="private_room_in_house">
                      Private room in house
                    </option>
                  </select>
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">
                    Number of Rooms: *
                  </label>
                  <input
                    type="text"
                    value={hotelInfo.NoRooms}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="NoRooms"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">City *</label>
                  <input
                    type="text"
                    value={hotelInfo.city}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="city"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className="PropertyText pl-2">Contact person: *</label>
                  <input
                    type="text"
                    value={hotelInfo.contactPerson}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="contactperson"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">Email: *</label>
                  <input
                    type="email"
                    value={hotelInfo.email}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="email"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">
                    Contact Number: *
                  </label>
                  <input
                    type="phone"
                    value={hotelInfo.Mobile}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="Mobile"
                  />
                </div>
                <div className="p-0 border-0 mb-4">
                  <label class=" ft-14 PropertyText">
                    <Checkbox  className='mt-1 chkBoxText p-0 ml-1' color="success" />
                    I agree with the terms and conditions of Roomph!
                  </label>
                </div>

                <br />
                <div className="p-0 border-0 text-center">
                  <button
                    type="submit"
                    onClick={hotelsubmit}
                    className="bg-orange btn-primary btn-large d-inline-block mx-auto px-3 py-2 text-white text-center  ft-20"
                  >
                    Send the form
                  </button>
                </div>
              </form> */}
            </div>

            <div className="border border-color border-radius px-3 py-3 my-4 bg-white">
              <h4 className="main-heading ft-20 text-d-gray ">
                Your Address Matters
              </h4>
              <p className="f-9 text-d-gray mb-0">
                Please make sure you enter the full address of your property,
                including building name, number, etc. Based on the information
                you provide, we’ll send an account manager to verify the
                property.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Property;
