import React, { Component, useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { fetch_carousel } from "../../../server";

function OffersSlider() {
  const [image, setImage] = useState("");

  const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    loop: false,
    dots: false,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const fetchApiData = async () => {
    const data = await fetch_carousel();
    setImage(data.Collection.Rows[0]);
  };
  useEffect(() => {
    fetchApiData();
  }, []);
  return (
    <section className="mtt-3 position-relative z-0 cursor">
      <div className="container oo_outer">
        {image !== "" ? (
          <OwlCarousel className="owl-theme-des mb-3" {...options}>
            <Link
              className="slide-item-container"
              to={image.banner_url ? image.banner_url : ""}
            >
              <div
                className="slide_item"
                style={{
                  backgroundImage: `url(https://roomph.ascendant.travel/${image.Image})`,
                }}
              >
                <div className="text-white slider-text">{image.text}</div>
              </div>
            </Link>
          </OwlCarousel>
        ) : (
          <div className="slide-item-container">
            <Skeleton className="slide_item" />
          </div>
        )}
      </div>
    </section>
  );
}

export default OffersSlider;
// export default class OffersSlider extends Component {
//   state = {
//     image: '',
//     post: '',
//     responseToPost: '',
//   };

//   componentDidMount() {
//     this.callApi()
//       .then(res => this.setState({
//         image: res.express.Collection.Rows[0].banner_img
//       }))
//       .catch(err => console.log(err));
//   }

//   callApi = async () => {
//     const response = await fetch('/api/hello1');
//     const body = await response.json();
//     if (response.status !== 200) throw Error(body.message);

//     return body;
//   };
//   render() {
//     return (

//     );
//   }
// }
