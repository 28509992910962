import React, { useEffect } from "react";
import "aos/dist/aos.css";
import Topbar from "../Topbar/Topbar";
import Footer from "../footer/Footer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, withRouter } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import history from ".././history";
import { initiateCheckout, getForeeCheckoutURL } from "foree-checkout";
import swal from "sweetalert";
import moment from "moment";
import { AiFillStar } from "react-icons/ai";
import FormInput from "../BasicComponents/FormInput";
import { confirmed_booking, page_view } from "../../OrangeLoftGTM";
import {
  createreservation,
  getaccommodationdetail,
  updatebookingstatus,
} from "../../server";

function CustomerInformation() {
  page_view("check_out");
  const location = useLocation();
  const {
    hotelParams,
    roomId,
    Total,
    extraids,
    guest_qtys,
    ratePlanId,
    ratesbydate,
    roomqty,
    RefundStatusCheck,
  } = location.state;
  const [locationstate, setlocationstate] = useState(location.state);
  const [hotelDeatails, sethotelDeatails] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [bookingDEtail, setbookingDEtail] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [Tax, setTax] = useState(0);
  const [Taxname, setTaxname] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [finalPrice, setfinalPrice] = useState(Total);
  function percentage(num, per) {
    return (num / 100) * per;
  }

  const handleOnChange = (e) => {
    setIsChecked(e.target.checked);
  };

  function initiateMyCheckout(
    hotelID,
    BookingId,
    PinCode,
    totalPrice,
    email,
    mobileNumber,
    promoArray
  ) {
    let URL = getForeeCheckoutURL();
    var foreeDEtail = {
      key: "ec45ffa0-f178-49d8-a400-829650b9a7d7",
      amount: totalPrice,
      create_bill: true,
      reference_number: BookingId,
      callback: callback,
      // 'callback_url': `http://localhost:3000/information/`,
      customer_email_address: email,
      customer_phone_number: mobileNumber,
      bill_details_id: BookingId,
    };
    initiateCheckout(foreeDEtail, false);
    window.onmessage = async (e) => {
      if (e.origin !== URL) return;
      console.log(e.data);
      if (e.data.status == "2") {
        const canceldata = await updatebookingstatus({
          accommodationid: hotelID,
          bookingid: BookingId,
          pincode: PinCode,
          status: 9,
        });
        console.log(canceldata);
      } else {
        const canceldata = await updatebookingstatus({
          accommodationid: hotelID,
          bookingid: BookingId,
          pincode: PinCode,
          status: 1,
        });
        console.log(canceldata);
      }
    };
  }
  function callback(param) {
    console.log(param);
    window.onmessage = (e) => {
      if (e.origin !== URL) return;
      // console.log(e.data);
    };
  }
  //spinner
  const [contactInfo, setContactInfo] = useState({
    fullname: "",
    Lastname: "",
    phone: "",
    city: "",
    cnic: "",
    email: "",
    Country: "",
    address: "",
  });
  const onChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };
  const [user, setUser] = useState("");
  const [userId, setuserId] = useState("");
  const inputs = [
    {
      id: 0,
      name: "fullname",
      type: "text",
      placeholder: "First Name",
      errorMessage: "It should be a valid name!",
      label: "",
      required: true,
    },
    {
      id: 1,
      name: "Lastname",
      type: "text",
      placeholder: "Last Name",
      errorMessage: "It should be a valid name!",
      label: "",
      required: true,
    },
    {
      id: 2,
      name: "phone",
      type: "text",
      placeholder: "Phone Number",
      errorMessage: "Phone Number Required!",
      label: "",
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "",
      required: true,
    },
    {
      id: 6,
      name: "cnic",
      type: "text",
      placeholder: "CNIC/Passport",
      errorMessage: "It should be a valid CNIC/Passport no.!",
      label: "",
      required: true,
    },
    {
      id: 7,
      name: "address",
      type: "text",
      placeholder: "Address",
      errorMessage: "It should be a valid address!",
      label: "",
      required: true,
    },
    {
      id: 8,
      name: "city",
      type: "text",
      placeholder: "City/State",
      errorMessage: "It should be a valid City/State!",
      label: "",
      required: true,
    },
    {
      id: 9,
      name: "Country",
      type: "text",
      placeholder: "Country",
      errorMessage: "It should be a valid Country!",
      label: "",
      required: true,
    },
  ];
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      // console.log(JSON.parse(localStorage.getItem("user")).result.result.data[0].profile_id[0])
    }
  }, []);

  let handleSubmit = async (e) => {
    setspinner(true);
    e.preventDefault();
    confirmed_booking(
      Total,
      roomId,
      ratePlanId,
      roomqty,
      hotelParams.checkin,
      hotelParams.checkout,
      hotelParams.nights,
      hotelParams.adults
    );
    try {
      let Rdata = {
        ...contactInfo,
        name: contactInfo.fullname.split(" ")[0],
        postHotelId: hotelParams.id,
        check_in: hotelParams.checkin,
        check_out: hotelParams.checkout,
        Total: finalPrice.toString(),
        roomId: roomId,
        ratePlanId: ratePlanId,
        extraids: extraids,
        guest_qtys: guest_qtys,
        ratesbydate: ratesbydate,
        ProfileId: "",
        roomqty: roomqty,
        cc_type: isChecked ? 18 : 36,
        bookingstatus: isChecked ? 1 : 5,
      };
      const BookingData = await createreservation(Rdata);
      if (isChecked) {
        // console.log(BookingData.ReservationCreate.Success[0].Result[0]);
        setbookingDEtail(BookingData.ReservationCreate.Success[0].Result[0]);
        setspinner(false);
        history.push({
          pathname: "/thankyou",
          state: {
            hotelParams: hotelParams,
            Taxname: Taxname,
            Tax: Tax,
            roomqty: roomqty.split(",").length,
            contactInfo: contactInfo,
            bookingDEtail: BookingData.ReservationCreate.Success[0].Result[0],
            hotelDeatails: hotelDeatails,
            Total: Total,
          },
        });
      } else {
        setspinner(false);
        initiateMyCheckout(
          hotelParams.id,
          BookingData.ReservationCreate.Success[0].Result[0].BookingID,
          BookingData.ReservationCreate.Success[0].Result[0].PinCode,
          finalPrice,
          contactInfo.email,
          contactInfo.phone
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApiData = async () => {
    const data = await getaccommodationdetail(hotelParams.id);
    //console.log(data);
    sethotelDeatails(data.getHotels.Success[0].Result[0]);
    if (data.getHotels.Success[0].Result[0].hoteltaxes[0].length !== 0) {
      data.getHotels.Success[0].Result[0].hoteltaxes[0].Tax.map((tax) => {
        setTax(Number((tax.$.TaxPercent * Total) / 100));
        setfinalPrice(
          Number(finalPrice + Number((tax.$.TaxPercent * Total) / 100))
        );
        setTaxname(
          `${Number(tax.$.TaxPercent).toLocaleString()}% ${tax.$.TaxName}`
        );
        setLoading(true);
        //setfinalPrice(Number(finalPrice + Number(tax.$.TaxPercent * Total / 100)));
      });
    } else {
      setLoading(true);
    }
  };

  useEffect(() => {
    fetchApiData();
  }, []);
  return (
    <>
      <Topbar color="#FF3D00" page="property" user={user} setUser={setUser} />
      <section className="box-shadow-2 CustomerInfo">
        <div className="container-3 py-2">
          <div className="px-lg-5 d-flex align-items-center justify-content-center py-0 m-0">
            <div className="step1">1</div>
            <hr
              style={
                !isMobile
                  ? { width: "89%", color: "#fff" }
                  : { width: "50%", color: "#fff" }
              }
            />
            <div className="step2">
              <FaCheck />
            </div>
            {/* <button className="step2"><SiNike /><p className="CI">Booking Confirmed</p></button> */}
          </div>
          <div className="d-flex m-0 p-0">
            <div>
              <p
                className="f-12 line-1 text-center mt-2"
                style={{ color: "#fff" }}
              >
                Customer Information
              </p>
            </div>
            <div style={!isMobile ? { width: "70%" } : { width: "25%" }}></div>
            <div>
              <p
                className="f-12 line-1 text-center mt-2"
                style={{ color: "#fff" }}
              >
                Booking Confirmed
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className={isMobile ? "container-2" : "container"}>
          <div className="row mt-3">
            <div className="col-lg-5 col-md-5 col-12 bg-white border-20 box-shadow-2">
              <div className="row py-lg-3 px-lg-3 px-1 py-3">
                <div className="col-lg-7 col-md-7 col-7">
                  {Loading ? (
                    <h6 className="mt-2 main-heading overflow-texts">
                      {hotelDeatails.AccommodationName}
                    </h6>
                  ) : (
                    <Skeleton className="mt-2 main-heading"></Skeleton>
                  )}
                  {Loading ? (
                    <p className="d-flex align-item-center">
                      {" "}
                      {Array.apply(null, {
                        length: hotelDeatails.Rating
                          ? Number(hotelDeatails.Rating)
                          : 0,
                      })
                        .map(Number.call, Number)
                        .map((item) => {
                          return (
                            <AiFillStar
                              className="f-13 start-green"
                              key={item}
                            />
                          );
                        })}
                    </p>
                  ) : (
                    <Skeleton className="d-flex align-item-center"></Skeleton>
                  )}

                  <p className="mt-1 text-gray mb-4 f-12">
                    {Loading ? hotelDeatails.Address : <Skeleton />}
                  </p>
                </div>
                <div className="col-lg-5 col-md-5 col-5">
                  {Loading ? (
                    <img
                      className="hotelImgCI"
                      style={{ borderRadius: "15px" }}
                      src={
                        hotelDeatails.hotelImages[0].Image_URLs[0].$.Image_URL
                      }
                      alt=""
                    />
                  ) : (
                    <Skeleton
                      className="hotelImgCI"
                      style={{ borderRadius: "15px" }}
                    />
                  )}
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 border-bottom-form pb-2 mt-4">
                  {/* {()=>{StandardDateFn(hotelParams.checkin)}} */}
                  <p className="row f-12">
                    <span className="col-lg-6 col-6 fouth-heading">
                      Check-in:
                    </span>
                    <span className="col-lg-6 col-6 text-gray">
                      {moment(hotelParams.checkin, "YYYY-MM-DD").format(
                        "Do MMM YYYY, dddd"
                      )}
                    </span>
                  </p>
                  <p className="row f-12">
                    <span className="col-lg-6 col-6 fouth-heading">
                      Check-out:
                    </span>
                    <span className="col-lg-6 col-6 text-gray">
                      {moment(hotelParams.checkout, "YYYY-MM-DD").format(
                        "Do MMM YYYY, dddd"
                      )}
                    </span>
                  </p>

                  <p className="row f-12">
                    <span className="col-lg-6 col-6 fouth-heading">Stay:</span>
                    <span className="col-lg-6 col-6 text-gray">
                      {hotelParams.nights} Night
                      {hotelParams.nights > 1 ? "s" : ""},{" "}
                      {roomqty.split(",").length} Room
                      {roomqty.split(",").length > 1 ? "s" : ""},{" "}
                      {hotelParams.adults} Adult
                      {hotelParams.adults > 1 ? "s" : ""}
                    </span>
                  </p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                  <p className="row f-12">
                    <span className="col-lg-6 col-6 fouth-heading">
                      {roomqty ? roomqty.split(",").length : hotelParams.rooms}{" "}
                      Room ({hotelParams.nights ? hotelParams.nights : ""} Night
                      {hotelParams.nights > 1 ? "s" : ""}):
                    </span>
                    <span className="col-lg-6 col-6 text-gray">
                      Rs. {Number(Total).toLocaleString()}
                    </span>
                  </p>

                  {Loading ? (
                    <p className="row f-12">
                      <span className="col-lg-6 col-6 fouth-heading">
                        Tax ({Taxname}):
                      </span>
                      <span className="col-lg-6 col-6 text-gray">
                        Rs. {Number(Tax).toLocaleString()}
                      </span>
                    </p>
                  ) : (
                    <Skeleton></Skeleton>
                  )}
                  <p className="row f-12">
                    <span className="col-lg-6 col-6 fouth-heading">
                      Total Price:
                    </span>
                    <span className="col-lg-6 col-6 text-gray">
                      Rs. {(Number(Total) + Number(Tax)).toLocaleString()}
                    </span>
                  </p>
                </div>
                {/* <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center mt-3">
                  <button className="btn thankew-btn">Free Breakfast</button>
                  <button className="btn thankew-btn ml-1">Book Now Pay Later</button>
                </div> */}
              </div>
            </div>
            <div
              className="col-lg-7 col-md-7 col-sm-12"
              style={{ marginTop: isMobile ? "20px" : "" }}
            >
              <form
                onSubmit={handleSubmit}
                className="row py-lg-3 px-lg-3 px-1 py-3 ml-lg-1 bg-white border-20 box-shadow-2"
              >
                <h6 className="mb-3 f-18 fouth-heading">
                  Let us know who you are
                </h6>
                {inputs.map((input, index) =>
                  input.id == 4 || input.id == 5 ? (
                    <div className="col-6 col-lg-6 mt-3" key={index}>
                      <FormInput
                        {...input}
                        value={contactInfo[input.name]}
                        onChange={onChange}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-6 col-12 mt-3" key={index}>
                      <FormInput
                        {...input}
                        value={contactInfo[input.name]}
                        onChange={onChange}
                      />
                    </div>
                  )
                )}
                {/* <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <input type="text" class="form-control form-control-sm" id="exampleInputEmail1" placeholder="Full name *" aria-describedby="emailHelp" value={contactInfo.fullname} name="fullname" onChange={handleChange} required />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <input type="email" class="form-control form-control-sm" placeholder="Email *" id="exampleInputPassword1" value={contactInfo.email} name="email" onChange={handleChange} required />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <input type="email" class="form-control form-control-sm" placeholder="Retype email *" id="exampleInputPassword1" value={contactInfo.reemail} name="reemail" onChange={handleChange} required />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-3 pr-lg-0">
                  <input type="tel" class="form-control form-control-sm" placeholder="Phone number *" id="exampleInputPassword1" value={contactInfo.phone} name="phone" onChange={handleChange} required />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-3 pl-lg-2">
                  <input type="text" class="form-control form-control-sm" placeholder="City *" id="exampleInputPassword1" value={contactInfo.city} name="city" onChange={handleChange} required />
                </div> */}

                {/* {RefundStatusCheck == false ?  */}
                {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-12 position-relative">
                  <div className="d-inline-flex mt-3">
                    <label className="custom-checkbox-1">
                      <input type="checkbox" checked={isChecked} onChange={handleOnChange} />
                      <span></span>
                    </label>
                    <span className="roboto-regular f-12 ml-2" style={{ position: "relative" }}>I don't have a credit card</span>
                  </div>
                </div>  */}
                {/* : null} */}

                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <p className="f-12 mt-3">
                    We’ll send your confirmation to this email address. By
                    processing with this booking, I am agreeing to Orangeloft’s{" "}
                    <Link to="/terms-condition" style={{ color: "#EF4E22" }}>
                      Terms & Conditions
                    </Link>
                  </p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                  <button type="submit" className="btn btn-primary-seach">
                    {spinner ? (
                      <div
                        class="spinner-border spinner-border-sm text-light p-1 mr-2"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : null}
                    Book Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default withRouter(CustomerInformation);
