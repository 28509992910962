import React, { useState, useEffect } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { RoomImageSlider } from "./PropertyImageSlider";
import { BsFillPersonFill } from "react-icons/bs";
import { room_drop_down_select } from "../../../OrangeLoftGTM";
import { getavailability } from "../../../server";

const CustomSelect = ({ properties1, NoOfRoomsAvailable, RatePlanId, RoomId, data, Rate, MaxPerson, RefundStatus, rateBydate }) => {
    //console.log(properties1);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);

    const optionsList = Array.apply(null, { length: Number(NoOfRoomsAvailable) + 1 }).map(Number.call, Number);
    // console.log(optionsList)
    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
    };
    const setSelectedThenCloseDropdown = (index, option) => {
        setSelectedOption(index);
        setIsOptionsOpen(false);
        room_drop_down_select(Rate[0],properties1.RoomId, properties1.RoomName, properties1.RatePlanId,MaxPerson)
        if (data[RoomId]) {
            data[RoomId].RatePlanId[RatePlanId] = { quantity: option, Rate: Rate, MaxPerson: MaxPerson, RefundStatus: RefundStatus, rateBydate: rateBydate }
            // console.log(data);

        } else {
            data[RoomId] = { RatePlanId: [] }
            data[RoomId].RatePlanId[RatePlanId] = { quantity: option, Rate: Rate, MaxPerson: MaxPerson, RefundStatus: RefundStatus, rateBydate: rateBydate }
            //console.log(data);
        }
    };



    return (
        <div className="w-100 position-relative">
            <button
                type="button"
                aria-haspopup="listbox"
                aria-expanded={isOptionsOpen}
                className="customer-drop"
                onClick={toggleOptions}
            // onKeyDown={handleListKeyDown}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <p className="f-12">{optionsList[selectedOption] == 0 ? 'No. of Rooms' : 'Room'}</p>
                    {
                        optionsList[selectedOption] !== 0 ? <p className="f-12">{optionsList[selectedOption]} {isOptionsOpen ? <BsChevronUp className="ml-3 main-color" /> : <BsChevronDown className="ml-3 main-color" />}</p>
                            :
                            isOptionsOpen ? <BsChevronUp className="ml-2 main-color" /> : <BsChevronDown className="ml-2 main-color" />
                    }
                </div>
            </button>
            <ul
                className={`options ${isOptionsOpen ? "show" : ""}`}
                role="listbox"
                aria-activedescendant={optionsList[selectedOption]}
                tabIndex={-1}
            // onKeyDown={handleListKeyDown}
            >
                {optionsList.map((option, index) => (
                    index <= 10 ?
                        <li
                            id={option}
                            role="option"
                            key={index}
                            aria-selected={selectedOption == index}
                            tabIndex={0}
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedThenCloseDropdown(index, option);
                            }}

                        >
                            {option ? `Room ${option} (Rs. ${(Number(option) * Number(Rate)).toLocaleString()})` : `Room ${option}`}
                        </li> : null
                ))}
            </ul>
        </div>
    );
};


function AvailableRooms({ hotelParams, data, setData, feturedImage }) {
    const [properties1, setproperties1] = useState([]);
    const getAPIdata= async ()=>{
        var Rdata={ postID: hotelParams.id, postCheckIn: hotelParams.checkin, postCheckOut: hotelParams.checkout };
        const data= await getavailability(Rdata);
        setproperties1(data.Success.Result[0].HotelRooms);

    }
    useEffect(() => {
    getAPIdata();
    }, [])
    return (
        properties1.map((properties1, index) => {
            if (properties1.RatePlanDetails[0].RatePlans && properties1.RatePlanDetails[0].RatePlans.length !== 0) {
                return (
                    <div className="room-container bg-white" key={index}>
                        <RoomImageSlider RoomName={properties1.RoomName} imgSrc={properties1.RoomImages ? properties1.RoomImages[0].RoomImage : null} feturedImage={feturedImage} />
                        <div className="px-lg-4 px-2 main-heading mt-3 text-orange">{properties1.RoomName}</div>

                        {
                            properties1.RatePlanDetails[0].RatePlans.map((ratePlan, index) => {
                                return (
                                    <div className="px-lg-4 px-2" key={index}>
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex .align-items-baseline mt-1">
                                                <span className="sub-heading">{ratePlan.RatePlanName}</span>
                                            </div>
                                            <div className="d-flex .align-items-baseline justify-content-end">
                                                <p className="f-14 max">max:&nbsp;</p>
                                                <div>
                                                    <img src={`images/Review-image/${ratePlan.MaxPerson ? Number(ratePlan.MaxPerson) : 0}.svg`} className="max-image"></img>
                                                    {/* // Array.apply(null, {length: ratePlan.MaxPerson ? Number(ratePlan.MaxPerson) : 0 }).map(Number.call, Number).map((item) => {
                                                        //     return <BsFillPersonFill className="person" key={item} />
                                                        // }) */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between p-0 mt-1">
                                            <div><p className="f-14 max">Rs.&nbsp;<span className="f-14 max">{ratePlan.Rate ? Number(ratePlan.Rate).toLocaleString() : null}/-</span></p>
                                                {/* {ratePlan.RefundStatus == 1 ? <span className="policy-span-free">Free Cancellation</span> : <span className="policy-span">Non-Refundable</span>} */}
                                            </div>
                                            <div className="py-1 px-0 m-0"><CustomSelect properties1={properties1} MaxPerson={ratePlan.MaxPerson} Rate={ratePlan.Rate} RoomId={properties1.RoomId} RatePlanId={ratePlan.RatePlanId} data={data} NoOfRoomsAvailable={ratePlan.NoOfRoomsAvailable} RefundStatus={ratePlan.RefundStatus} rateBydate={ratePlan.RateDetailsByDate[0].RatePerDate[0].$.Rate} /></div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                )
            }

        })
    )
}

export default AvailableRooms
