import React, { Component, createContext, useMemo, useState, useCallback } from "react";
import './results.css';
import Checkbox from '@mui/material/Checkbox';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import history from '../../history';
import { Modal } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import Sorting from "./Sort/Sorting";
import Searchheader from "../../Topbar/Searchheader";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { isMobile } from 'react-device-detect';
import DataRange from "../../BasicComponents/DataRange";
import { LoadingCards, PropertyCard } from "./Cards";
import FilterProperty from "./FilterProperty";
import { MdArrowBackIosNew } from "react-icons/md";
import { get_accommodation_search_test } from "../../../server";

var checkIn = '';
var checkOut = '';
var Adults = '';
var mulImages = [];
var Children = '';
var Rooms = '';
var nights = '';
var priceStart = '';
var priceEnd = '';
var rating = '';
var CityName = '';
let idd = '';
var premium = '';
var category = '';


function Checkboxes() {
  const [checked, setChecked] = React.useState(false);
  const handleChange1 = (event) => {
    category = 4;
  };
  const handleChange2 = (event) => {
    rating = 3;
    setChecked(event.target.checked);
  };
  const handleChange3 = (event) => {
    rating = 2;
  };
  const handleChange4 = (event) => {
    rating = 1;
  };
  const handleChange5 = (event) => {
    category = '8';
  };
  const handleChange6 = (event) => {
    category = '0';
  };
  const handleChange7 = (event) => {
    category = '5';
  };
  return (
    <div>
      <Checkbox onChange={handleChange1} inputProps={{ 'aria-label': 'controlled' }} className='chkbox1r' color="success" /><img className="chkBoxTextN" src={process.env.PUBLIC_URL + "/images/Asset16.png"} width={80} alt="" /><br />
      <Checkbox checked={checked} onChange={handleChange2} className='mt-1 p-0 ml-1' color="success" /><img src={process.env.PUBLIC_URL + "/images/Asset99.svg"} className="imgWidr chkBoxTextN" alt="" /><img src={process.env.PUBLIC_URL + "/images/Asset99.svg"} className="imgWidr" alt="" /><img src={process.env.PUBLIC_URL + "/images/Asset99.svg"} className="imgWidr" alt="" /><br />
      <Checkbox onChange={handleChange3} className='mt-1 p-0 ml-1' color="success" /><img src={process.env.PUBLIC_URL + "/images/Asset99.svg"} className="imgWidr chkBoxTextN" alt="" /><img src={process.env.PUBLIC_URL + "/images/Asset99.svg"} className="imgWidr" alt="" /><br />
      <Checkbox onChange={handleChange4} className='mt-1 p-0 ml-1' color="success" /><img src={process.env.PUBLIC_URL + "/images/Asset99.svg"} className="imgWidr chkBoxTextN" alt="" /><br />
      <p className="m-0 p-0"><Checkbox onChange={handleChange5} className='mt-1 chkBoxText p-0 ml-1' color="success" /><span className='chkBoxText chkBoxTextN' style={{ fontFamily: 'Gotham Rounded Medium' }}>Hotel</span><br /></p>
      <p className="m-0 p-0"><Checkbox onChange={handleChange6} className='mt-1 chkBoxText p-0 ml-1' color="success" /><span className='chkBoxText chkBoxTextN' style={{ fontFamily: 'Gotham Rounded Medium' }}>Apartment</span><br /></p>
      <p className="m-0 p-0"><Checkbox onChange={handleChange7} className='mt-1 chkBoxText p-0 ml-1' color="success" /><span className='chkBoxText chkBoxTextN' style={{ fontFamily: 'Gotham Rounded Medium' }}>Guest House</span></p>
      <p className="m-0 p-0"><Checkbox onChange={handleChange7} className='mt-1 chkBoxText p-0 ml-1' color="success" /><span className='chkBoxText chkBoxTextN' style={{ fontFamily: 'Gotham Rounded Medium' }}>Lowest Price</span></p>
      <p className="m-0 p-0"><Checkbox onChange={handleChange7} className='mt-1 chkBoxText p-0 ml-1' color="success" /><span className='chkBoxText chkBoxTextN' style={{ fontFamily: 'Gotham Rounded Medium' }}>Highest Price</span></p>
      <p className="m-0 p-0"><Checkbox onChange={handleChange7} className='mt-1 chkBoxText p-0 ml-1' color="success" /><span className='chkBoxText chkBoxTextN' style={{ fontFamily: 'Gotham Rounded Medium' }}>Lowest Rating</span></p>
      <p className="m-0 p-0"><Checkbox onChange={handleChange7} className='mt-1 chkBoxText p-0 ml-1' color="success" /><span className='chkBoxText chkBoxTextN' style={{ fontFamily: 'Gotham Rounded Medium' }}>Highest Rating</span></p>
    </div>
  );
}

export class Results extends Component {
  
  constructor() {
    super();
    this.state = {
      showHide: false,
    }
    this.state = {
      showHide1: false,
    }
    this.state = {
      onestar: false,
      filterShow: false,
      twostar: false,
      threestar: false,
      hotelRating: 0,
      PropertyRating: [],
      citysearch: '',
      checkinnSearch: '',
      checkoutSearch: '',
      showCalender: false,
      slidevalue: [0, 30000],

    }
  }

  setshowCalender(val) {
    this.setState({ showCalender: val })
  }

  setshowCalender(val) {
    this.setState({ showCalender: val })
  }

  handleCityChange(city) {
    //console.log(city);
    this.setState({ loading: !this.state.loading });
    this.callApi(city)
      .then(res => {
        this.setState({
          properties: res.Success.result,
          propertiesCopy: res.Success.result,
          Count: res.Success.result[0].RowCount,
          CityName: res.Success.result[0].CityName
          // img: res.Success.result[0].AccommodationImages[0].URL[0]
        });
        this.setState({ loading: true })
      })
      .catch(err => {
        console.log(err);
        this.setState({
          properties: [],
          propertiesCopy: [],

        });
        this.setState({ loading: true })

      });
    this.setState({ loading: !this.state.loading });

    // var ratingaa = new Array();
    // if (this.state.twostar == true) {
    //   console.log("2")
    //   ratingaa.push(2);
    // }
    // if (this.state.onestar) {
    //   console.log("1")
    //   ratingaa.push(1);
    // } if (this.state.threestar) {
    //   ratingaa.push(3);
    //   console.log("3")

    // }
    // this.setStateOfParentloading();
    // console.log(ratingaa.toString());
    // this.callApi(this.state.slidevalue[0], this.state.slidevalue[0], ratingaa.toString(), this.state.hotelRating, this.state.PropertyRating.toString(), cityH)
    //   .then(res => {
    //     this.setState({
    //       properties: res.Success.result,
    //       Count: res.Success.result[0].RowCount,
    //       CityName: res.Success.result[0].CityName
    //     });
    //     this.setStateOfParentloading();

    //   })
    //   .catch(err => console.log(err));
  }


  handlePropertyChange(e) {
    this.setStateOfParentloading();

    var oneAraay = new Array();
    // console.log("propery", e.target.value);
    if (e.target.checked) {
      this.state.PropertyRating.push(e.target.value);
      console.log(this.state.PropertyRating);
      this.state.PropertyRating.map(pro => {
        if (pro == "5") {
          oneAraay = oneAraay.concat(this.state.propertiesCopy.filter(p => p.PropertyCategoryId[0].split(",").includes("5") && !p.PropertyCategoryId[0].split(",").includes("8") && p.MinRate[0] !== ""));

        } else {
          oneAraay = oneAraay.concat(this.state.propertiesCopy.filter(p => p.PropertyCategoryId[0].split(",").includes(pro.toString()) && p.MinRate[0] !== ""));

        }
      })

    } else {
      var index = this.state.PropertyRating.indexOf(e.target.value);
      console.log(index);
      if (index !== -1) {
        this.state.PropertyRating.splice(index, 1);
        console.log(this.state.PropertyRating);
        this.state.PropertyRating.map(pro => {
          if (pro == "5") {
            oneAraay = oneAraay.concat(this.state.propertiesCopy.filter(p => p.PropertyCategoryId[0].split(",").includes("5") && !p.PropertyCategoryId[0].split(",").includes("8") && p.MinRate[0] !== ""));

          } else {
            oneAraay = oneAraay.concat(this.state.propertiesCopy.filter(p => p.PropertyCategoryId[0].split(",").includes(pro.toString()) && p.MinRate[0] !== ""));

          }
        })
      }
    }

    setTimeout(() => {
      oneAraay = [...new Set(oneAraay)];
      oneAraay = oneAraay.sort(function (a, b) {
        return b.MinRate[0] - a.MinRate[0];
      });
      this.setStateOfParent(oneAraay ? oneAraay : this.state.propertiesCopy);
      this.setStateOfParentloading();

    }, 500);

  }


  handleHotelRateing(rr) {
    this.setStateOfParentloading();
    console.log(rr)
    var oneAraay = this.state.propertiesCopy.filter(p => parseFloat(p.UserRating[0]) >= parseFloat(rr * 2) && p.UserRating[0] <= parseFloat(10) && p.MinRate[0] !== "");
    console.log(oneAraay)
    if (oneAraay) {
      oneAraay = oneAraay.sort(function (a, b) {
        return b.UserRating[0] - a.UserRating[0];
      });

    }

    setTimeout(() => {
      this.setStateOfParent(oneAraay);
      this.setStateOfParentloading();

    }, 500);
    // var ratingaa = new Array();
    // if (this.state.twostar == true) {
    //   console.log("2")
    //   ratingaa.push(2);
    // }
    // if (this.state.onestar) {
    //   console.log("1")
    //   ratingaa.push(1);
    // } if (this.state.threestar) {
    //   ratingaa.push(3);
    //   console.log("3")

    // }
    // console.log(ratingaa.toString());
    // this.setState({ hotelRating: rr });
    // this.callApi(this.state.slidevalue[0], this.state.slidevalue[0], ratingaa.toString(), rr)
    //   .then(res => {
    //     this.setState({
    //       properties: res.Success.result,
    //       Count: res.Success.result[0].RowCount,
    //       CityName: res.Success.result[0].CityName
    //       // img: res.Success.result[0].AccommodationImages[0].URL[0]
    //     });
    //     this.setStateOfParentloading();

    //   })
    //   .catch(err => console.log(err));
  }


  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide })
  }
  handleFilterModal() {
    this.setState({ filterShow: !this.state.filterShow })
  }
  //handleFilterModal
  handleModalShowHide1() {
    this.setState({ showHide1: !this.state.showHide1 })
  }
  state = {
    loading: false, properties: [], propertiesCopy: [], pImgs: [], CityName: '', Cancellation: '', Count: '', img: '',
    post: '',
    responseToPost: '',
    value: 0,
  };
  setValue = (val) => {
    this.state.value = val;
  }

  setStateOfParent = (properties) => {
    this.setState({ properties: properties });
  }
  setStateOfParentloading = () => {

    this.setState({ loading: !this.state.loading });
  }
  setStateOfParentloadingFilter = () => {
    this.setState({ loading: !this.state.loading });
    this.callApi()
      .then(res => {
        console.log(res)
        this.setState({
          properties: res.Success.result,
          Count: res.Success.result.length,
          CityName: res.Success.result[0].CityName
          // img: res.Success.result[0].AccommodationImages[0].URL[0]
        });
        this.setState({ loading: true })
      })
      .catch(err => console.log(err));
  }

  StarChange = e => {
    this.setStateOfParentloading();
    console.log(e.target.checked);
    console.log(e.target.value);
    var Fetchproperties = [];
    if (e.target.value == 1) {
      this.setState({ onestar: !this.state.onestar });
    }
    if (e.target.value == 2) {
      this.setState({ twostar: !this.state.twostar })

    }
    if (e.target.value == 3) {
      this.setState({ threestar: !this.state.threestar });

    }

    setTimeout(() => {
      if (this.state.threestar) {
        var oneAraay = this.state.propertiesCopy.filter(p => p.Rating[0] == "3" && p.MinRate[0] !== "");
        Fetchproperties = Fetchproperties.concat(oneAraay);

      }
      if (this.state.twostar == true) {
        var oneAraay = this.state.propertiesCopy.filter(p => p.Rating[0] == "2" && p.MinRate[0] !== "");
        Fetchproperties = Fetchproperties.concat(oneAraay);

      }
      if (this.state.onestar) {
        var oneAraay = this.state.propertiesCopy.filter(p => p.Rating[0] == "1" && p.MinRate[0] !== "");
        Fetchproperties = Fetchproperties.concat(oneAraay);

      }
      this.setStateOfParent(Fetchproperties.length !== 0 ? Fetchproperties : this.state.propertiesCopy);
      this.setStateOfParentloading();

    }, 500);


  };


  clearProperty() {
    window.location.reload(false);
  }



  filtersForDesktop(newValue) {
    this.setStateOfParentloading();
    // console.log(newValue);
    var oneAraay = this.state.propertiesCopy.filter(p => (p.MinRate[0] >= newValue[0] && p.MinRate[0] <= newValue[1]) && p.MinRate[0] !== "");
    oneAraay = oneAraay.sort(function (a, b) {
      return b.MinRate[0] - a.MinRate[0];
    });

    setTimeout(() => {
      this.setStateOfParent(oneAraay);
      this.setStateOfParentloading();
    }, 500)

  }


  componentDidMount() {
    this.callApi()
      .then(res => {

        this.setState({
          properties: res.Success.result,
          propertiesCopy: res.Success.result,
          Count: res.Success.result[0].RowCount,
          CityName: res.Success.result[0].CityName
          // img: res.Success.result[0].AccommodationImages[0].URL[0]
        });
        this.setState({ slidevalue: [0, res.Success.result.sort(function (a, b) { return b.MinRate[0] - a.MinRate[0] })[0].MinRate[0]] })
        this.setState({ loading: true })
      })
      .catch(err => {
        console.log(err);
        this.setState({
          properties: [],
          propertiesCopy: [],

        });

        this.setState({ loading: true })

      });
  }



  callApi = async (cityy, priceS, priceE, ratings, hotelRating, propertycat) => {
    checkIn = this.props.match.params.checkin;
    checkOut = this.props.match.params.checkout;
    Adults = this.props.match.params.adults;
    Rooms = this.props.match.params.rooms;
    nights = this.props.match.params.nights;
    priceStart = priceS ? priceS : "";
    priceEnd = priceE ? priceE : "";
    premium = '';
    rating = ratings ? ratings : '';
    category = propertycat ? propertycat : "8,5,2,10,4";
    var userRating = hotelRating ? hotelRating : '';
    var data= { postCity: cityy ? cityy : this.props.match.params.city, postCheckIn: this.props.match.params.checkin, postCheckOut: this.props.match.params.checkout, postAdults: this.props.match.params.adults, postRooms: this.props.match.params.rooms, postPriceStart: priceStart, postPriceEnd: priceEnd, postRating: rating, postPremium: premium, postCategory: category, userRating: userRating };
    const response= await get_accommodation_search_test(data);
    console.log(response);
    // const response = await fetch('/api/world', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body:,
    // })
    // const body = await response.json();
    // if (response.status !== 200) throw Error(body.message);
    this.setState({ responseToPost: response });
    return response;
  };

  render() {
  
    return (

      <section className="vh-50">
        <Modal show={this.state.showHide} centered>
          <div style={{ backgroundColor: 'white', width: '350px', height: '75px', position: 'absolute', zIndex: '100' }}>
            <button onClick={() => this.handleModalShowHide()} className="mt-2 ml-3" style={{ border: "none", background: "none", paddingRight: '400px' }}><MdOutlineKeyboardArrowLeft /></button>
          </div>
          <div className="mt-1 mb-3">
            <DataRange display={true} />
          </div>
          <button className="dateDoneBtn mx-auto mb-3" onClick={() => { history.push(`/propertylisting/${this.props.city}/${this.props.checkIn}/${this.props.checkOut}/${this.props.adults}/${this.props.rooms}/${this.props.nights}/${priceStart}/${priceEnd}/${premium}/${rating}/${category}`); this.handleModalShowHide(); window.location.reload(false); }}>Done</button>
        </Modal>

        <Modal show={this.state.showHide1} centered>
          <button onClick={() => this.handleModalShowHide1()} className="mt-2 ml-3" style={{ border: "none", background: "none", paddingRight: '400px' }}><MdOutlineKeyboardArrowLeft /></button>
          <button className="dateDoneBtn mx-auto mb-3" onClick={() => { history.push(`/propertylisting/${this.props.city}/${this.props.checkIn}/${this.props.checkOut}/${this.props.adults}/${this.props.rooms}/${this.props.nights}/${priceStart}/${priceEnd}/${premium}/${rating}/${category}`); this.handleModalShowHide(); window.location.reload(false); }}>Done</button>
        </Modal>
        <Searchheader properties={this.state.properties} propertiesCopy={this.state.propertiesCopy} setStateOfParentloading={this.setStateOfParentloading} setStateOfParent={this.setStateOfParent} city={this.props.match.params.city} checkIn={this.props.match.params.checkin} checkOut={this.props.match.params.checkout} rooms={this.props.match.params.rooms} adults={this.props.match.params.adults} handleCityChange={this.handleCityChange.bind(this)} />


        {/* <div className="container-fluid">
          <div className="row">
            <div className="col dfl">
              <div id="box-search">
                <div className="thumbnail">
                  <img className="featuredImg" src="https://i1.wp.com/frugal2fab.com/wp-content/uploads/2017/01/8.jpg?resize=1170%2C548&ssl=1" alt="hotel" />
                  */}
        {/* <Image className="featuredImg" src={process.env.PUBLIC_URL + "/images/slider.png"} alt="hotel" fluid /> */}
        {/* <div className="caption">
                    <img src={process.env.PUBLIC_URL + "/images/Asset16.png"} alt="" />
                  </div>
                  <div className="overlay">
                  </div>
                  <div className="caption1">
                    <div className="container d-flex justify-content-between align-items-center py-2 px-3">
                      <div className="d-flex align-items-center">
                        <img className="m-1px" src={process.env.PUBLIC_URL + "/images/Asset99.svg"} width={20} height={20} alt="" />
                        <img className="m-1px" src={process.env.PUBLIC_URL + "/images/Asset99.svg"} width={20} height={20} alt="" />
                        <img className="m-1px" src={process.env.PUBLIC_URL + "/images/Asset99.svg"} width={20} height={20} alt="" />
                        <p className="f-18">&nbsp;&nbsp;Pine View Hotel</p>
                      </div>
                      <div>
                        <p className="f-18">Rs. 7,500 <span>/ night</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="main-section">
          <div className="container-2">
            <div className="row d-lg-none d-md-none my-4 mx-2">
              <div className="col-4 mr-2 p-0">
                <Sorting properties={this.state.properties} setStateOfParentloading={this.setStateOfParentloading} setStateOfParent={this.setStateOfParent} />
              </div>
              <div className="col-4 m-0 p-0">
                <button className='Buttons' onClick={() => this.handleFilterModal()}>Filter</button>
                <div className={this.state.filterShow ? 'sort-popup h-100 w-100 bg-white position-fixed z-index-999 top-0 sort-popup-show' : 'sort-popup h-100 w-100 bg-white position-fixed z-index-999 top-0 sort-popup-hide'}>
                  <p className="ft-18 line-height-21 gr-medium py-3 text-center bg-orange w-100 text-white z-index">Filter</p>
                  <a onClick={() => this.handleFilterModal()} className="p-3 top-0 position-absolute sort-popup-close z-index"> <MdArrowBackIosNew className="fa fa-angle-left d-inline-block text-white" /> </a>
                  <div className="p-4">
                    <FilterProperty handleFilterModal={this.handleFilterModal.bind(this)} setState={this.setState.bind(this)} filtersForDesktop={this.filtersForDesktop.bind(this)} clearProperty={this.clearProperty.bind(this)} handlePropertyChange={this.handlePropertyChange.bind(this)} state={this.state} StarChange={this.StarChange} handleHotelRateing={this.handleHotelRateing.bind(this)} />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="container">
          {/* <div className="row mx-lg-0 mx-2">
            <div className="col-12 mb-1 mt-3">
              {this.state.Count ? <u>Showing {this.state.properties.filter(p => p.MinRate[0] !== '').length} properties - {this.state.CityName}</u> : <Skeleton></Skeleton>}
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-12">
              <div className="signUpCapsule box-shadow-2"><u>Sign up</u> and get 20% off on your first booking.</div>
            </div>
          </div> */}
          <div className="row mt-2 mt-lg-5 mt-md-5">
            {!isMobile ?
              <div className="col-lg-4 col-md-4 col-4 position-realtive">
                <div className="destination-sidebar w-100 py-4">
                  <FilterProperty handleFilterModal={this.handleFilterModal.bind(this)} setState={this.setState.bind(this)} filtersForDesktop={this.filtersForDesktop.bind(this)} clearProperty={this.clearProperty.bind(this)} handlePropertyChange={this.handlePropertyChange.bind(this)} state={this.state} StarChange={this.StarChange} handleHotelRateing={this.handleHotelRateing.bind(this)} />
                </div>
              </div>
              : null}

            <div className="col-lg-8 col-md-8 col-12 Propertylistingcontainer">
              {this.state.loading ?
                <PropertyCard Adults={this.props.match.params.adults} Rooms={this.props.match.params.rooms} showCalender={this.state.showCalender} setshowCalender={this.setshowCalender.bind(this)} properties={this.state.properties} checkIn={this.state.checkinnSearch ? this.state.checkinnSearch : this.props.match.params.checkin} checkOut={this.state.checkoutSearch ? this.state.checkoutSearch : this.props.match.params.checkout} /> :
                <LoadingCards />
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Results);