import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { diff_days } from "../../BasicComponents/CommonFunc";
import moment from "moment";
import { click_top_hotel } from "../../../OrangeLoftGTM";
import { fetch_topcities } from "../../../server";
const options = {
  margin: 18,
  responsiveClass: true,
  nav: true,
  loop: false,
  dots: false,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

function Explore() {
  const [responseArray, setresponseArray] = useState([]);
  const fetchApiData = async () => {
    const data = await fetch_topcities();
    setresponseArray(data.Collection.Rows);
  };
  useEffect(() => {
    fetchApiData();
  }, []);

  return (
    <section className="mt-4 position-relative explore-back">
      <div className="container py-4">
        <div className="explore-row-container">
          <div className="explore-row-left">
            <p className="top">Explore</p>
            <h6 className="cc">Beautiful</h6>
            <span className="notton">Locations</span>
          </div>
          <div className="explore-row-right pt-2">
            {responseArray.length !== 0 ? (
              <OwlCarousel className="owl-theme-explore" {...options}>
                {responseArray.map((city, index) => {
                  return (
                    <div className="exlolore-outer">
                      <Link
                        onClick={() =>
                          click_top_hotel(
                            city.CityName,
                            city.AccommodationId,
                            city.AccommodationName
                          )
                        }
                        to={`/hotels&city=${city.CityName.replace(
                          /<(.|\n)*?>/g,
                          ""
                        )}&check_in=${moment().format(
                          "YYYY-MM-DD"
                        )}&check_out=${moment()
                          .add(1, "days")
                          .format(
                            "YYYY-MM-DD"
                          )}&adult=2&rooms=1&nights=1&hotel_id=${
                          city.AccommodationId
                        }`}
                      >
                        <div className="explore">
                          <img
                            className="exploreCityImage box-shadow-1"
                            src={
                              city.Image
                                ? `https://roomph.ascendant.travel/${city.Image}`
                                : "images/murree1.jpeg"
                            }
                            alt="Room"
                          />
                          <div className="pt-2 text-center">
                            <span className="text-white explore-heading">
                              {city.AccommodationName}
                            </span>
                            <span className="text-style">
                              <MdLocationOn className="f-13" />
                              {city.CityName.replace(/<(.|\n)*?>/g, "")}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </OwlCarousel>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Explore;
