import axios from "axios";
import { API_BASE_URL_BOOKINGWHIZZ, API_URL } from "./API";
import xml2js from "xml2js";

export async function fetch_banner() {
  try {
    const url = API_URL + "/fetch_banner?limit=1";
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function fetch_reviews(hotelId) {
  try {
    const url = hotelId
      ? API_URL + "/fetch_reviews?hotel_id=" + hotelId
      : API_URL + "/fetch_reviews";
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function fetch_carousel() {
  try {
    const url = API_URL + "/fetch_carousel?limit=1";
    const response = await axios.get(url);
    //console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

//hello2
export async function fetch_topcities() {
  try {
    const url = API_URL + "/fetch_topcities";
    const response = await axios.get(url);
    //console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function fetch_blogs() {
  try {
    const url = API_URL + "/fetch_blogs";
    const response = await axios.get(url);
    //console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

//hotelsList
export async function search_accommodation() {
  try {
    const url = API_URL + "/search_accommodation";
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function get_accommodation_search_test(data) {
  try {
    let city = data.postCity.trim();
    let checkin = data.postCheckIn.trim();
    let checkout = data.postCheckOut.trim();
    let adults = data.postAdults.trim();
    let rooms = data.postRooms.trim();
    let priceRangeStart = data.postPriceStart !== " " ? data.postPriceStart : 0;
    let priceRangeEnd = data.postPriceEnd !== " " ? data.postPriceEnd : 10000;
    let premium = data.postPremium ? data.postPremium.trim() : "";
    let rating = data.postRating ? data.postRating.trim() : "";
    let userRating = data.userRating ? data.userRating.trim() : "";
    let category = data.postCategory ? data.postCategory.trim() : "";
    // var url = `http://beapi.bookingwhizz.com/Connect.svc/xml/getaccommodationsearchtest?userid=10025&password=H0JKKU7R4E&accommodationids=&agentid=25&cityname=${city}&checkin=${checkin}&checkout=${checkout}&limits=100&offset=0&Sortby=2&Sort=1&&multilanguageid=1&fullbook=1&rooms=${rooms}&adults=${adults}&pricerangestart=${priceRangeStart}&pricerangeend=${priceRangeEnd}&ratings=${rating}&userrating=${userRating}`;
    var url = `${API_BASE_URL_BOOKINGWHIZZ}/getaccommodationsearchtest?userid=10025&password=H0JKKU7R4E&accommodationids=&agentid=25&cityname=${city}&checkin=${checkin}&checkout=${checkout}&limits=100&offset=0&Sortby=2&Sort=1&&multilanguageid=1&fullbook=1&rooms=${rooms}&adults=${adults}&pricerangestart=${priceRangeStart}&pricerangeend=${priceRangeEnd}&ratings=${rating}&userrating=${userRating}`;
    const response = await axios.get(url);
    const result = await parseXml(response.data);
    return result;
  } catch (error) {
    throw error;
  }
}

//hotelDetails
export async function getaccommodationdetail(postHotelId) {
  try {
    var url = `${API_BASE_URL_BOOKINGWHIZZ}/getaccommodationdetail?userid=10025&password=H0JKKU7R4E&accommodationid=${postHotelId}&multilanguageid=1`;
    const response = await axios.get(url);
    const result = await parseXml(response.data);
    return result;
  } catch (error) {
    throw error;
  }
}

//bookings
export async function getuserreservations(proId) {
  try {
    var url = `${API_BASE_URL_BOOKINGWHIZZ}/getuserreservations?userid=10025&password=H0JKKU7R4E&profileid=${proId}`;
    const response = await axios.get(url);
    const result = await parseXml(response.data);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getavailability(data) {
  try {
    let idd = data.postID;
    let checkin = data.postCheckIn;
    let checkout = data.postCheckOut;
    var url = `http://beapi.bookingwhizz.com/Connect.svc/xml/getavailability?userid=10025&password=H0JKKU7R4E&accommodationid=${idd}&checkin=${checkin}&checkout=${checkout}&multilanguageid=1`;
    const response = await axios.get(url);
    const result = await parseXml(response.data);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function createreservation(data) {
  try {
    var url = `http://beapi.bookingwhizz.com/Connect.svc/xml/createreservation?userid=10025&password=H0JKKU7R4E&accommodationid=${data.postHotelId}&roomids=${data.roomId}&rateplanids=${data.ratePlanId}&extraids=${data.extraids}&roomqty=${data.roomqty}&checkin=${data.check_in}&checkout=${data.check_out}&booker_firstname=${data.name}&booker_lastname=''&booker_email=${data.email}&booker_telephone=${data.phone}&cc_type=${data.cc_type}&bookingstatus=${data.bookingstatus}&booker_street=''&booker_zipcode=''&booker_city=${data.city}&booker_country=PK&guest_qtys=${data.guest_qtys}&guest_names=${data.name}&guest_emails=${data.email}&guest_telephones=${data.phone}&comments=''&totalprice=${data.Total}&ratesbydate=${data.ratesbydate}&roomids=${data.roomId}&rateplanids=${data.ratePlanId}&payment_method=&charged_amount=10&reservation_type=&channelids=&promotion_code=&pms=0&pmsids=0&discounted_price=0.00&partnerid=BW&loyaltypoints=&loyaltydiscount=&membertype=&programname=&signupdate=&statuscode=&membership_id=&converted_currency=&converted_price=0.00&sourceid=orangeloft&profileid=&multilanguageid=1&cc_no=&cc_cvc=`;
    const response = await axios.get(url);
    const result = await parseXml(response.data);
    return result;
  } catch (error) {
    throw error;
  }
}
export async function updatebookingstatus(data) {
  try {
    var url = `http://beapi.bookingwhizz.com/Connect.svc/xml/updatebookingstatus?userid=10025&password=H0JKKU7R4E&accommodationid=${data.accommodationid}&bookingid=${data.bookingid}&status=${data.status}`;
    const response = await axios.get(url);
    const result = await parseXml(response.data);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function add_hotel(data) {
  try {
    var url = "https://roomph.ascendant.travel/roomph_api/add_hotel";
    //var url = API_URL + "/add_hotel";
    const response = await axios.post(url, JSON.stringify(data));
    return response.data;
  } catch (error) {
    throw error;
  }
}


async function parseXml(xmlData) {
  return new Promise((resolve, reject) => {
    xml2js.parseString(xmlData, (error, result) => {
      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
    });
  });
}
