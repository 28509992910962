import React from 'react'
import CustomCheckBox from "../../BasicComponents/CustomCheckBox";
import StarRating from "../../BasicComponents/StarRating";
import { Slider } from 'antd';
import 'antd/dist/antd.css';
import { isMobile } from 'react-device-detect';

function FilterProperty({
    clearProperty,
    handlePropertyChange,
    state,
    handleFilterModal,
    setState,
    StarChange,
    handleHotelRateing,
    filtersForDesktop
}) {
    return (
        <>

            <div className="col-12 px-0 position relative">
                <p className="font-weight-normal ft-21 text-gray mb-3">Price Range</p>
                <Slider
                    min={0}
                    max={19000}
                    trackStyle={{
                        background: "#FF8500"
                    }}
                    handleStyle={{
                        borderColor: "#FF8500"
                    }}
                    tooltip={{ open: false }}
                    range={{ draggableTrack: true }}
                    value={state.slidevalue}
                    onChange={(val) => { setState({ slidevalue: val }) }}
                    onAfterChange={(val) => filtersForDesktop(val)}
                />
                <div className="w-100 d-flex justify-content-between">
                    <p className="text-grey ft-1rem">{Number(state.slidevalue[0]).toLocaleString()}</p>
                    <p className="text-grey ft-1rem">{Number(state.slidevalue[1]).toLocaleString()}</p>
                </div>
            </div>
            <div className="col-12 px-0 mt-3">
                {/* //mt-55 */}
                <div className="star-rating">
                    <p className="font-weight-normal ft-21 text-gray mb-2">Star Ratings</p>
                    <label className={state.onestar == true ? 'waves-light btn btn-orange' : 'waves-effect waves-light btn btn-flat'}>
                        <input type="checkbox" name="onestar" id="onestar" value="1" onChange={StarChange} />1</label>
                    <label className={state.twostar == true ? 'waves-light btn btn-orange' : 'waves-effect waves-light btn btn-flat'}>
                        <input type="checkbox" name="twostar" id="twostar" value="2" onChange={StarChange} />2</label>
                    <label className={state.threestar == true ? 'waves-light btn btn-orange' : 'waves-effect waves-light btn btn-flat'}>
                        <input type="checkbox" name="threestar" id="threestar" value="3" onChange={StarChange} />3</label>
                </div>
            </div>
            <div className="col-12 px-0">
                <div className="star-rating">
                    <p className="font-weight-normal ft-21 text-gray mb-2">Guest Ratings</p>

                    <StarRating handleHotelRateing={handleHotelRateing}></StarRating>

                </div>
            </div>
            {/* <div className="col-12 px-0">
                <p className="font-weight-normal ft-21 text-gray mb-3">Room Type</p>
                <div className="all-room-detail"> */}
            {/* <div className="custom-control custom-checkbox ml-2"> */}
            {/* <CustomCheckBox value="5" onChange={handlePropertyChange} name="premium" id="roomphpremium" label="Orange Loft Premium" className="ml-2" />
                    <CustomCheckBox value="8" onChange={handlePropertyChange} name="roomph_standard" id="roomphstandard" label="Orange Loft Standard" className="ml-2" /> */}

            {/* 
                    <div className="all-room-check p-2 position-relative">
                        <div className="ww-50 line-height-095 mr-5 mt-2 position-absolute right-0 text-right top-0 w-h-50">
                            <img src="images/logo.png" width={90} className="mr-2"/>
                        </div>
                        <CustomCheckBox value="2" onChange={handlePropertyChange} name="guesthouse" id="roomphguesthouse" label="Bed &amp; Breakfast" />
                        <CustomCheckBox value="10" onChange={handlePropertyChange} name="apartment" id="roomphapartment" label="Apartment" />
                        <CustomCheckBox value="4" onChange={handlePropertyChange} id="roomphprivate" name="private" label="Private Home" />
                    </div> */}
            {/* </div> */}
            <div className="d-flex justify-content-center algin-items-center mt-3">
                <button className="btn btn-topbar-primary" onClick={clearProperty}>Clear</button>
                {isMobile ?
                    <button className="btn btn-topbar-primary ml-3" onClick={() => setState({ filterShow: false })}>Done</button>
                    : null}

            </div>
            {/* </div> */}
        </>
    )
}

export default FilterProperty
