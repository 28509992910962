import React, { useState, useRef } from "react";
import Skeleton from 'react-loading-skeleton'
import OwlCarousel from 'react-owl-carousel';
import { Link } from "react-router-dom";
import history from '../../history';
import clsx from "clsx";
import useLazyLoad from '../../BasicComponents/useLazyLoad'
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import { AiFillStar } from "react-icons/ai";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ReactStars from "react-rating-stars-component";
import { isMobile } from 'react-device-detect';
import { property_click } from "../../../OrangeLoftGTM";


const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: "hidden",
    height: "400",
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: "8px 10px",
    borderRadius: "10px",
};

function CustomArrows(props) {

    return (
        <div>
            <OwlCarousel className='owl-theme owl-theme-property-listing' nav items={1} dots={false} slideSpeed={200} responsiveClass={true}>
                <LightgalleryProvider>
                    {props.imgSrc.map((img, index) => (
                        <LightgalleryItem src={img} >
                            <div key={index} className='item'>
                                <img className="sliderImgs" src={img} alt="" />
                            </div>
                        </LightgalleryItem>
                    ))}
                </LightgalleryProvider>

            </OwlCarousel>
        </div>
    );

}
export function LoadingCards() {
    return (
        <>
            <div className="row cards-property-destination-skeleton">
                <div className="col-5 h-45">
                    <Skeleton className="h-45"></Skeleton>
                </div>
                <div className='col hotel-details-container col-7 d-flex flex-column justify-content-between h-45'>
                    <div>
                        <Skeleton className="mb-1" width="100%"></Skeleton>
                        <Skeleton className="mb-1 line-height" width="60%"></Skeleton>
                        <Skeleton className="mb-1 line-height" width="40%"></Skeleton>
                    </div>

                    <div>

                        <Skeleton className="mb p-0 line-height" width="40%"></Skeleton>
                        <Skeleton width="100%"></Skeleton>
                    </div>
                </div >
            </div>
            <div className="row mt-4 cards-property-destination-skeleton">
                <div className="col-5 h-45">
                    <Skeleton className="h-45"></Skeleton>
                </div>
                <div className='col hotel-details-container col-7 d-flex flex-column justify-content-between h-45'>
                    <div>
                        <Skeleton className="mb-1" width="100%"></Skeleton>
                        <Skeleton className="mb-1 line-height" width="60%"></Skeleton>
                        <Skeleton className="mb-1 line-height" width="40%"></Skeleton>
                    </div>

                    <div>

                        <Skeleton className="mb p-0 line-height" width="40%"></Skeleton>
                        <Skeleton width="100%"></Skeleton>
                    </div>
                </div >
            </div>
            <div className="row mt-4 cards-property-destination-skeleton">
                <div className="col-5 h-45">
                    <Skeleton className="h-45"></Skeleton>
                </div>
                <div className='col hotel-details-container col-7 d-flex flex-column justify-content-between h-45'>
                    <div>
                        <Skeleton className="mb-1" width="100%"></Skeleton>
                        <Skeleton className="mb-1 line-height" width="60%"></Skeleton>
                        <Skeleton className="mb-1 line-height" width="40%"></Skeleton>
                    </div>

                    <div>

                        <Skeleton className="mb p-0 line-height" width="40%"></Skeleton>
                        <Skeleton width="100%"></Skeleton>
                    </div>
                </div >
            </div>
        </>
    )
}


export function PropertyCard({ properties, checkIn, checkOut, showCalender, setshowCalender, Adults, Rooms }) {
    const NUM_PER_PAGE = 10;
    const TOTAL_PAGES = properties.length;
    const triggerRef = useRef(null);

    const onGrabData = (currentPage) => {
        // This would be where you'll call your API

        return new Promise((resolve) => {
            setTimeout(() => {
                if (data.length < properties.length) {
                    if (properties.length == 1) {
                        resolve({ data: properties, hasMore: true });

                    } else {
                        const fdata = properties.slice(
                            ((currentPage - 1) % TOTAL_PAGES) * NUM_PER_PAGE,
                            NUM_PER_PAGE * (currentPage % TOTAL_PAGES)
                        );
                        resolve({ data: fdata, hasMore: true });
                    }

                } else {
                    resolve({ data: [], hasMore: false });
                }

            }, 1000);
        });
    };

    const { data, loading, hasMore } = useLazyLoad({ triggerRef, onGrabData });
    function diff_days(chk, out) {
        let dt1 = new Date(chk);
        let dt2 = new Date(out);
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

    }

    return (
        properties.length == 0 ?
            <div className="alert alert-warning f-12" role="alert">
                <i className="bi bi-emoji-frown-fill"></i>&nbsp;&nbsp;For the chosen location, there is no results. Please choose a different location.
            </div> :
            <>
                {data.map((properties, index) => (
                    <Link 

                    onClick={()=>property_click(properties.CityName,checkIn, checkOut,Adults,diff_days(checkIn, checkOut), properties.AccommodationId)}
                    
                    to={`/hotels&city=${properties.CityName}&check_in=${checkIn}&check_out=${checkOut}&adult=${Adults}&rooms=${Rooms}&nights=${diff_days(checkIn, checkOut)}&hotel_id=${properties.AccommodationId}`} key={index}>
                        <div className="d-flex cards-property-destination position-relative box-shadow-2">
                            <div className="cards-cityimage">
                                {/* {console.log(properties)} */}
                                <img src={properties.AccommodationImages ? properties.AccommodationImages[0].URL[0] : properties.ImageURL[0]} alt="" />
                            </div>
                            <div className="py-1 pl-3">
                                <div className="cityNameCount">
                                    <span className="cards-des_p_name sub-heading">{properties.AccommodationName}</span>
                                    <p className="cards-stars">
                                        {/* {
                                        Array.apply(null, { length: properties.Rating ? Number(properties.Rating[0]) : 0 }).map(Number.call, Number).map((item) => {
                                            return <AiFillStar className="f-13 Primary-color" key={item} />
                                        })
                                    } */}
                                        <ReactStars
                                            count={5}
                                            value={properties.UserRating[0] !== '' ?  parseFloat(parseFloat(properties.UserRating[0]) / 2).toFixed(1) : '0.0'}
                                            size={isMobile ? 17 : 23}
                                            edit={false}
                                            activeColor="#FF8500"
                                            color="#C7C7C7"
                                            isHalf={true}
                                            emptyIcon={<i className="far fa-star"></i>}
                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                            fullIcon={<i className="fa fa-star"></i>}
                                        />
                                    </p>
                                    <p className="f-10 cards-propert-text mt-2">{properties.GeneralDescription}</p>
                                </div>
                                <div className="mt-lg-2 mt-3 d-flex justify-content-between">
                                    <div className="excellent-container black-color d-flex">
                                        <div className="excellent">
                                            {properties.UserRating[0] !== '' ?  parseFloat(parseFloat(properties.UserRating[0]) / 2).toFixed(1) : '0.0'}
                                        </div>
                                        <div className="excellent-text">Excellent</div>

                                    </div>
                                    <div className="excellent-price-container text-right pt-1">
                                        <div className="excellent-price">{properties.MinRate[0] ? `Rs. ${Number(properties.MinRate[0]).toLocaleString()}/-` : 'MISSED IT!'}</div>
                                        <div className="pernight">Per night</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>

                ))
                }
                {hasMore == false ?
                    <NoMoreData /> : <div ref={triggerRef} className={clsx("trigger", { visible: loading })}>
                        <LoadingCards />
                    </div>}


            </>
    )
};

const NoMoreData = () => {
    return (
        <div className="row my-4">
            <div className="col-12 nodata">
                No more Results
            </div>
        </div>
    )
}


