import React, { Component, useEffect, useState } from "react";
import Footer from "../footer/Footer";
import { withRouter } from "react-router-dom";
import './thankyou.css';
import Topbar from "../Topbar/Topbar";
import { FaCheck } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import moment from "moment";
import { AiFillStar } from "react-icons/ai";
import { page_view } from "../../OrangeLoftGTM";


function Thankyou(props) {
  const [user, setUser] = useState('');
  const location = useLocation();
  console.clear();
  console.log(location.state)
  const { hotelParams, bookingDEtail, hotelDeatails, Total, contactInfo, RefundStatusCheck, roomqty, Taxname, Tax } = location.state;

  useEffect(() => {
    console.log(location.state);
    page_view('Thank_you')
  }, [])
  return (
    <>
      <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
      <section className="box-shadow-3 booking-progress" >
        <div className="container-3 py-2">
          <div className="px-4 d-flex align-items-center justify-content-center py-0 m-0">
            <div className="step1">1</div>
            <hr className="baar" />
            <div className="step1"><FaCheck /></div>
            {/* <button className="step2"><SiNike /><p className="CI">Booking Confirmed</p></button> */}
          </div>
          <div className="d-flex m-0 p-0">
            <div><p className="f-12 line-1 text-center mt-2 bookinf-text" >Customer Information</p></div>
            <div style={{ width: '95%' }}></div>
            <div><p className="f-12 line-1 text-center mt-2 bookinf-text" >Booking Confirmed</p></div>
          </div>
        </div>

      </section>
      <section className="my-5">
        <div className={isMobile ? 'container-2' : 'container py-2'}>
          <div className="row">
            <div className="col-lg-7 col-md-7 col-12 px-3">
              <h5 className="sub-heading mt-3">Thank you, {contactInfo.fullname}! Your booking is now complete.
              </h5>
              <ul className="mt-3 final-booking f-12">
                <li>You chose to pay at the property. Orangeloft will not charge
                  your credit card.</li>
                <li>In the next 10 minutes, you will receive an email containing
                  your booking details.</li>
                <li>Your Booking ID is {bookingDEtail.BookingID}</li>
                <li>Please present this information at the check-in.</li>
                <li>To help manage your bookings, you can sign-in to Orangeloft if you did’nt book as a guest.</li>
              </ul>
            </div>
            <div className="col-lg-5 col-md-5 col-12 bg-white border-20 box-shadow-2">
                <div className="row py-lg-3 px-lg-3 px-1 py-3">
                  <div className="col-lg-7 col-md-7 col-7">
                    <h6 className="mt-2 main-heading overflow-texts">{hotelDeatails.AccommodationName}</h6>
                    {
                    <p className="d-flex align-item-center"> {Array.apply(null, { length: hotelDeatails.Rating ? Number(hotelDeatails.Rating) : 0 }).map(Number.call, Number).map((item) => {
                        return <AiFillStar className="f-13 start-green" key={item} />
                      })}</p>
                    }

                    <p className="mt-1 text-gray mb-4 f-12">
                    {hotelDeatails.Address }
                    </p>
                  </div>
                  <div className="col-lg-5 col-md-5 col-5">
                    <img className="hotelImgCI" style={{ borderRadius: '15px' }} src={hotelDeatails.hotelImages[0].Image_URLs[0].$.Image_URL} alt="" />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 border-bottom-form pb-lg-2 mt-4">
                    {/* {()=>{StandardDateFn(hotelParams.checkin)}} */}
                    <p className="row f-12"><span className="col-lg-6 col-6 fouth-heading">Check-in:</span><span className="col-lg-6 col-6 text-gray">{moment(hotelParams.checkin).format('Do MMM YYYY, dddd')}</span></p>
                    <p className="row f-12"><span className="col-lg-6 col-6 fouth-heading">Check-out:</span><span className="col-lg-6 col-6 text-gray">{moment(hotelParams.checkout).format('Do MMM YYYY, dddd')}</span></p>

                    <p className="row f-12"><span className="col-lg-6 col-6 fouth-heading">Stay:</span><span className="col-lg-6 col-6 text-gray">{hotelParams.nights} Night{hotelParams.nights > 1 ? 's' : ''}, {roomqty} Room{roomqty > 1 ? 's' : ''}, {hotelParams.adults} Adult{hotelParams.adults > 1 ? 's' : ''}</span></p>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <p className="row f-12">
                      <span className="col-lg-6 col-6 fouth-heading">{roomqty ? roomqty : hotelParams.rooms} Room ({hotelParams.nights ? hotelParams.nights : ''} Night{hotelParams.nights > 1 ? 's' : ''}):</span><span className="col-lg-6 col-6 text-gray">Rs. {Number(Total).toLocaleString()}</span></p>

                    <p className="row f-12">
                      <span className="col-lg-6 col-6 fouth-heading">Tax ({Taxname}):</span><span className="col-lg-6 col-6 text-gray">Rs. {Number(Tax).toLocaleString()}</span>
                    </p>
                    <p className="row f-12">
                      <span className="col-lg-6 col-6 fouth-heading">Total Price:</span><span className="col-lg-6 col-6 text-gray">Rs. {(Number(Total) + (Number(Tax))).toLocaleString()}</span></p>

                  </div>
                  {/* <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center mt-3">
                  <button className="btn thankew-btn">Free Breakfast</button>
                  <button className="btn thankew-btn ml-1">Book Now Pay Later</button>
                </div> */}
                </div>
              
    

            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default withRouter(Thankyou);
