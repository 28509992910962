import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import StarRateIcon from '@mui/icons-material/StarRate';
import ReactStars from "react-rating-stars-component";
import { isMobile } from 'react-device-detect';
import { fetch_reviews } from '../../../server';

function Review() {

  return (
    <section className='mtt-3'>
      <div className='container'>
        <h5 className="text-center main-heading mt-4 mb-2">Traveler Reviews</h5>
        <ReviewSlider />
      </div>
    </section>

  )
}


export const ReviewSlider = ({ hotelId }) => {
  const [ReviewsArray, setReviews] = useState([]);
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    loop: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1.5,
      },
      400: {
        items: 1.5,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: hotelId ? 2 : 3.24,

      }
    },
  };


  const fetchApiData = async () => {
    const data = await fetch_reviews();
    setReviews(data.Collection.Rows);

  };
  useEffect(() => {
    fetchApiData();
  }, []);

  return (
    ReviewsArray.length !== 0 ?
      <OwlCarousel className='owl-theme mb-3'  {...options}>
        {ReviewsArray.map((Review, index) => {
          return (
            <div className="hotel-reviews-container" key={index}>
              <div className="hotel-review-container-inner box-shadow-2">
                <div className="hotel-header-container">
                  <img src={`https://roomph.ascendant.travel/${Review.user_dp}`}></img>
                  <span className="d-flex flex-column"> <h4 className='mb-0 review-heading'>{Review.fullname}</h4>
                    {/* {console.log(Review.rating.split('|').reduce((sum, curr) => sum + Number(curr), 0) / Review.rating.split('|').length)} */}

                    <p className="revieicon">
                      <ReactStars
                        count={5}
                        value={Review.rating.split('|').reduce((sum, curr) => sum + Number(curr), 0) / Review.rating.split('|').length}
                        size={isMobile ? 17 : 23}
                        edit={false}
                        activeColor="#FF8500"
                        color="#C7C7C7"
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                      />
                    </p>

                  </span>

                </div>
                <div className="hotel-desc">
                  {Review.review}
                </div>

              </div>

            </div>

          )

        })}
      </OwlCarousel> : null
  )
}

export default Review