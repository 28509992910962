import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, ...inputProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };
  const [startDate, setStartDate] = useState('');



  return (
    inputProps.name == 'dob' ?

      <div className="formInput">
        {label ? <label>{label}</label>
          : null}
  
        <DatePicker
          placeholderText={inputProps.placeholder}
          selected={startDate} onChange={(date:Date) => setStartDate(date)} className="form-control form-control-sm" />
        <span className="inputspan">{errorMessage}</span>
      </div> :


      <div className="formInput">
        {label ? <label>{label}</label>
          : null}
        <input
          {...inputProps}
          onChange={onChange}
          onBlur={handleFocus}
          onFocus={() =>
            inputProps.name === "confirmPassword" && setFocused(true)
          }
          focused={focused.toString()}
          className="form-control form-control-sm"
        />
        <span className="inputspan">{errorMessage}</span>
      </div>
  );
};

export default FormInput;