import React, {useState} from "react";
import "aos/dist/aos.css";
import Topbar from "../Topbar/Topbar";
import Footer from "../footer/Footer";
import Results from './results/results';
import { withRouter } from "react-router-dom";
import { page_view } from "../../OrangeLoftGTM";

function SearchResultsPage() {
  const [user, setUser] = useState('');
  page_view('destination');

  return (
    <>
    <Topbar user={user} setUser={setUser} />
    <Results />
    <Footer  />
  </>
  )
}



export default withRouter(SearchResultsPage);
