import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Topbar from "../../Topbar/Topbar";
import Footer from "../../footer/Footer";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './profile.css';

export default function Profile() {
    const [fname, setfName] = useState([]);
    const [lname, setlName] = useState([]);
    const [email, setEmail] = useState([]);
    const [user, setUser] = useState('');

    useEffect(() => {
        const fuser = JSON.parse(localStorage.getItem('user'));
        console.log("user", fuser);
        if (fuser) {
            setfName(fuser.result.result.data[0].fname);
            setlName(fuser.result.result.data[0].lname);
            setEmail(fuser.result.result.data[0].email);
        }

    }, [user]);

    return (
        <section className="vh-100 d-flex flex-column justify-content-between">
            <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
            <div className="container">
                <Row>
                    <Col className="mt-3" xs={9}>
                        <h5 className="sub-heading">Personal details</h5>
                        <p className="profileText">Update your info and find out how it’s used</p>
                    </Col>
                    <Col>
                        <AccountCircleIcon fontSize='70px' className="profileIconP" />
                    </Col>
                </Row>
                <hr className="hrLine" />
                <Row>
                    <Col className="" xs={10}>
                        <h5 className="sub-heading">Name</h5>
                        <p className="profileText">{fname}{' '}{lname}</p>
                    </Col>
                </Row>
                <hr className="hrLine" />
                <Row>
                    <Col className="" xs={10}>
                        <h5 className="sub-heading">Display Name</h5>
                        <p className="profileText">{fname}</p>
                    </Col>
                </Row>
                <hr className="hrLine" />
                <Row>
                    <Col className="" xs={10}>
                        <h5 className="sub-heading">Email <button className="verified"> Verified </button></h5>
                        <p className="profileText">{email}</p>
                        <p className="profileText">This is the email adress you use to sign in. it’s also where we send your booking confirmations.</p>
                    </Col>
                </Row>

            </div>

            <Footer />
        </section>
    );

}

