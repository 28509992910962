import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FiUser } from "react-icons/fi";
import RoomphLogo from "../../Assests/logo.svg";
import Button from "../BasicComponents/Button";
import Logowhite from "../../Assests/logo-white.svg";
import Modal from "@mui/material/Modal";
import Signup from "../registeration/signup";
import ForgetPass from "../registeration/forgotpass";
import UserSignIn from "../registeration/UserSignIn";
import VerifyAccount from "../registeration/verifyaccount";
import { link_click } from "../../OrangeLoftGTM";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // overflow:'scroll',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function Topbar(props) {
  const [open, setOpen] = useState({
    show: false,
    modaln: "signin",
  });
  const [openSignUp, setSignUp] = useState(false);
  const [openForgetPass, setForgetPass] = useState(false);
  const [UserProfieDrop, setUserProfieDrop] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      props.setUser(user.result.result.data[0].fname);
    }
  }, []);

  const handleOpen = (show, modal) => {
    setOpen({
      show: show,
      modaln: modal,
    });
  };
  const UserProfieDropfn = () => {
    setUserProfieDrop(!UserProfieDrop);
  };
  const handleClose = () => {
    setOpen({
      ...open,
      show: false,
    });
  };

  const handleLogout = () => {
    localStorage.clear();
    props.setUser("");
    // window.location.reload();
  };

  return (
    <section className="home-bar">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open.show}
        onClose={handleClose}
      >
        {open.modaln == "signin" ? (
          <UserSignIn
            handleOpen={handleOpen}
            setUser={props.setUser}
            setOpen={setOpen}
          />
        ) : open.modaln == "Verify" ? (
          <VerifyAccount handleOpen={handleOpen} />
        ) : open.modaln == "signUp" ? (
          <Signup handleOpen={handleOpen} setUser={props.setUser} />
        ) : open.modaln == "forgetpass" ? (
          <ForgetPass handleOpen={handleOpen} setUser={props.setUser} />
        ) : null}
      </Modal>

      <div className="container-2 py-1">
        <div className="display-flex w-100 j-between item-center">
          <div>
            <Link to="/" className="roomph-logo">
              <img
                className="roomphLogoH"
                src="images/OL Logo 2.png"
                alt="logo"
              />
            </Link>
          </div>
          <div className="">
            {/* {props.page == 'property' ?
                <Link to={{ pathname: '/Deals', state: { content: 'No deals yet !' } }}>
                  <Button childern="View Deals" type="button" buttonStyle="btn-secondary"></Button>
                </Link>
                :
                <Link to={{ pathname: '/Deals', state: { content: 'No deals yet !' } }}>
                  <Button childern="View Deals" type="button" buttonStyle="btn-primary"></Button>
                </Link>
              } */}

            {/* {props.user ?
                <span className="sign-in ml-1" onClick={UserProfieDropfn}>
                  <div class="dropdownTopbar">
                    <FiUser className='profileIcon' /> {props.user} <RiArrowDropDownLine className='dropDownIcon' />
                    {UserProfieDrop ?
                      <ul className="dropdown-contentTopbar">
                        <Link to='/profile'><li className='nav-item-drop'>Profile</li></Link>
                        <hr className='my-2'></hr>
                        <li className='nav-item-drop'>
                          <Link to='/booking'>Bookings</Link>
                        </li> 
                         <hr className='my-2'></hr>
                        <li className='nav-item-drop'>
                          <Link to='/Ambassador-Program'>
                            Ambassador Program
                          </Link>
                        </li>
                        <hr className='my-2'></hr>

                        <li className='nav-item-drop'>
                          <Link to="#" onClick={handleLogout}>Logout</Link>
                        </li>

                      </ul>
                      : null}
                  </div>
                </span> : <span className="sign-in ml-1" onClick={() => handleOpen(true, 'signin')}>Sign In</span>} */}

            {/* <span className="sign-in ml-1" onClick={props.user == '' ? () => handleOpen(true, 'signin') : UserProfieDropfn}>
                {props.user == '' ? 'Sign In' :
                  <div class="dropdownTopbar">
                    <FiUser className='profileIcon' /> {props.user} <RiArrowDropDownLine className='dropDownIcon' />
                    {UserProfieDrop ?
                      <ul className="dropdown-contentTopbar">
                        <Link to='/profile'><li className='nav-item-drop'>Profile</li></Link>
                        <hr className='my-2'></hr>
                       
                        <li className='nav-item-drop'>
                          <Link to='/Ambassador-Program'>
                            Ambassador Program
                          </Link>
                        </li>
                        <hr className='my-2'></hr>

                        <li className='nav-item-drop'>
                          <Link onClick={handleLogout}>Logout</Link>
                        </li>

                      </ul> : null}

                  </div>}
              </span> */}

            <Link
              onClick={() => link_click("add-Property")}
              to="/add-Property"
              class="btn btn-primary-3"
            >
              Register Now
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
