import React , {useState}from "react";
import "aos/dist/aos.css";
import Topbar from "../Topbar/Topbar";
import Footer from "../footer/Footer";
import Description from "./description/description";
import { useParams } from 'react-router-dom';
import { Router, Switch, Route } from "react-router-dom";
import history from '../../components/history';
import { page_view } from "../../OrangeLoftGTM";

// const params = useParams();
function PropertDetails() {
  const params = useParams();
  const [user, setUser] = useState('');
  page_view('hotels');

  return (
    <div className="Propertyll">
      <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
      <Description hotelParams={params} user={user} />
      {/* <Route path="/information" component={()=><CustomerInformation />}/> */}
   
      <Footer />
    </div>
  )
}

export default PropertDetails;
