import React, { useEffect, useState } from "react";
import AutoSearchFromApi from "./AutoSearchFromApi";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import moment from "moment";
import { isMobile } from "react-device-detect";
import history from "../../history";
import DateRangeIcon from "@mui/icons-material/DateRange";
import GroupsIcon from "@mui/icons-material/Groups";
import swal from "sweetalert";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// import Moment from 'react-moment';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { book_now_click } from "../../../OrangeLoftGTM";
import { fetch_banner } from "../../../server";

function SearchBox() {
  function diff_days(chk, out) {
    let dt1 = new Date(chk);

    let dt2 = new Date(out);

    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
  }
  const [SearchData, setSearchData] = useState({
    paragraph: "",
    heroImage: "",
    searchQuery: "",
    checkIn: new Date(),
    checkOut: addDays(new Date(), 1),
    showCalender: false,
    showDropdown: false,
    showsearch: false,
    state: [
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 1),
        key: "selection",
      },
    ],
    rooms: 1,
    adults: 2,
    childern: 0,
  });

  const fetchApiData=async()=>{
    const data= await fetch_banner();
    setSearchData({
      ...SearchData,
      paragraph: data.Collection.Rows[0].Description,
      heroImage: data.Collection.Rows[0].Image
    });
  }

  useEffect(() => {
    fetchApiData();
  }, []);

  function handleSubmit() {
    //console.log(JSON.stringify({ post: this.state.post }));
    if (SearchData.searchQuery == "") {
      swal({
        text: "Please select destination!",
        icon: "warning",
        buttons: false,
      });
    } else {
      if (!SearchData.searchQuery.AccommodationId) {
        book_now_click(
          'destination_search',
          SearchData.searchQuery.CityName,
          moment(SearchData.state[0].startDate).format(
          "YYYY-MM-D"
        ),moment(SearchData.state[0].endDate).format(
          "YYYY-MM-D"
        ),SearchData.adults,
        moment(SearchData.state[0].endDate).diff(moment(SearchData.state[0].startDate), 'days'));
        

        history.push(
          `/destination&city=${SearchData.searchQuery.CityName
          }&check_in=${moment(SearchData.state[0].startDate).format(
            "YYYY-MM-D"
          )}&check_out=${moment(SearchData.state[0].endDate).format(
            "YYYY-MM-D"
          )}&adult=${SearchData.adults}&rooms=${SearchData.rooms
          }&nights=${moment(SearchData.state[0].endDate).diff(moment(SearchData.state[0].startDate), 'days')}`
        );

      } else {
        book_now_click(
          'hotel_search',
          SearchData.searchQuery.CityName,
          moment(SearchData.state[0].startDate).format(
          "YYYY-MM-D"
        ),moment(SearchData.state[0].endDate).format(
          "YYYY-MM-D"
        ),SearchData.adults,
        moment(SearchData.state[0].endDate).diff(moment(SearchData.state[0].startDate), 'days'));
        history.push(
          `/hotels&city=${SearchData.searchQuery.CityName}&check_in=${moment(
            SearchData.state[0].startDate
          ).format("YYYY-MM-D")}&check_out=${moment(
            SearchData.state[0].endDate
          ).format("YYYY-MM-D")}&adult=${SearchData.adults}&rooms=${SearchData.rooms
          }&nights=${moment(SearchData.state[0].endDate).diff(moment(SearchData.state[0].startDate), 'days')}&hotel_id=${SearchData.searchQuery.AccommodationId}`
        );
      }
    }
  }

  return (
    <section className="hero_section" id="section" style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(https://roomph.ascendant.travel/${SearchData.heroImage})` }}>
      <div className="container h-100">
        <div className="hero_inner h-100">
          <div className="row mx-auto text-center selection-h mb-5">
            <h6 className="main-heading fadeInDown slowest go text-white">
              {SearchData.paragraph}
            </h6>
          </div>
          <div className="search-box mx-auto box-shadow-1 fadeInDown slowest go">
            <form>
              <div className="search">
                <LocationOnIcon className="searchicon f-30" />
                <span className="btn-search searchbox">
                  <span class="searchtext">Location</span>
                  <div className="home-auto position-relative">
                    <AutoSearchFromApi
                      setSearchData={setSearchData}
                      SearchData={SearchData}
                      fontSize='0.75rem'
                    />
                  </div>
                </span>
              </div>

              <div className="border-top"></div>
              <div className="d-flex position-relative">
                <div className="search position-relative">
                  <DateRangeIcon className="searchicon f-30" />
                  <button
                    className="btn-search"
                    onClick={(e) => {
                      e.preventDefault();
                      setSearchData({
                        ...SearchData,
                        showCalender: !SearchData.showCalender,
                        showDropdown: false,
                      });
                    }}
                  >
                    <div class="searchtext">Check in</div>
                    <div>
                      {moment(SearchData.state[0].startDate).format(
                        "MMM D, YYYY"
                      )}
                    </div>
                  </button>

                </div>
                <div className="border-right mx-3 my-1"></div>
                <div className="search position-relative">
                  <DateRangeIcon className="searchicon f-30" />
                  <span className="searchin">
                    <button
                      className="btn-search"
                      onClick={(e) => {
                        e.preventDefault();
                        setSearchData({
                          ...SearchData,
                          showCalender: !SearchData.showCalender,
                          showDropdown: false,
                        });
                      }}
                    >
                      <div class="searchtext ">Check out</div>
                      <div>
                        {moment(SearchData.state[0].endDate).format(
                          "MMM D, YYYY"
                        )}
                      </div>
                    </button>
                  </span>
                </div>

                {SearchData.showCalender ? (
                  <div
                    className="fadeInUp go animated position-absolute w-100 z-index-999 border-radius p-2 center-cal bg-white"
                    id="searchData1"
                  >
                    
                    <DateRange
                      onChange={(item) => {
                        console.log(item.selection.endDate);
                        if (
                          item.selection.endDate.getTime() ==
                          item.selection.startDate.getTime()
                        ) {
                          setSearchData({
                            ...SearchData,
                            state: [item.selection],
                          });
                        } else {
                          setSearchData({
                            ...SearchData,
                            state: [item.selection],
                            showCalender: false,
                          });
                        }
                      }}
                      rangeColors={["#FB8509"]}
                      moveRangeOnFirstSelection={false}
                      dragSelectionEnabled={false}
                      ranges={SearchData.state}
                      showDateDisplay={false}
                      months={isMobile ? 1 : 2}
                      minDate={moment().toDate()}
                      direction={isMobile ? "vertical" : "horizontal"}
                    />
                  </div>
                ) : null}
              </div>
              <div className="border-bottom"></div>
              <div className="d-flex position-relative">
                <div className="search position-relative">
                  <GroupsIcon className="searchicon f-30" />
                  <button
                    className="btn-search"
                    onClick={(e) => {
                      e.preventDefault();
                      setSearchData({
                        ...SearchData,
                        showDropdown: !SearchData.showDropdown,
                        showCalender: false,
                      });
                    }}
                  >
                    <div class="searchtext">Guests</div>
                    <div>
                      {SearchData.rooms} room{SearchData.rooms > 1 ? 's' : ''}, {SearchData.adults} adult{SearchData.adults > 1 ? 's' : ''}

                    </div>
                  </button>

                </div>

                <button type="button" className="btn btn-primary-2 w-60" onClick={(e) => { e.preventDefault(); handleSubmit(); }}>Book Now</button>
              </div>

              {SearchData.showDropdown ? (
                <div
                  className="bg-white float-left fadeInUp go animated  p-3 position-absolute shadow user-box z-index-999 mt-2 border-radius top-10"
                  style={{ display: "block" }}
                >
                  <ul className="list-unstyled">
                    <li className="d-block float-left w-100 mb-2">
                      <div className="qty">
                        <div className="float-left f-12">Room</div>
                        <div className="float-right text-right w-75">
                          {" "}
                          <span
                            className="room-minus"
                            onClick={() =>
                              setSearchData({
                                ...SearchData,
                                rooms:
                                  SearchData.rooms > 1
                                    ? SearchData.rooms - 1
                                    : 1,
                              })
                            }
                          >
                            -
                          </span>
                          <input
                            type="text"
                            className="room-count-input"
                            name="room_count"
                            value={SearchData.rooms}
                            disabled
                          />
                          <span
                            className="room-plus"
                            onClick={() =>
                              setSearchData({
                                ...SearchData,
                                rooms: SearchData.rooms + 1,
                              })
                            }
                          >
                            +
                          </span>{" "}
                        </div>
                      </div>
                    </li>
                    <li className="d-block float-left  w-100 mb-2">
                      <div className="qty">
                        <div className="float-left f-12">Adults</div>
                        <div className="float-right text-right w-75">
                          {" "}
                          <span
                            className="minus"
                            onClick={() =>
                              setSearchData({
                                ...SearchData,
                                adults: SearchData.adults > 1
                                  ? SearchData.adults - 1
                                  : 1,
                              })
                            }
                          >
                            -
                          </span>
                          <input
                            type="text"
                            className="count"
                            name="adult_count"
                            value={SearchData.adults}
                            disabled
                          />
                          <span
                            className="plus"
                            onClick={() =>
                              setSearchData({
                                ...SearchData,
                                adults: SearchData.adults + 1,
                              })
                            }
                          >
                            +
                          </span>{" "}
                        </div>
                      </div>
                    </li>
                    {/* <li className="d-block float-left  w-100 mb-2">
                            <div className="qty">
                              <div className="float-right text-right w-75">
                                {" "}
                                <span
                                  className="child-minus"
                                  onClick={() =>
                                    setSearchData({
                                      ...SearchData,
                                      childern:
                                        SearchData.childern > 0
                                          ? SearchData.childern - 1
                                          : 0,
                                    })
                                  }
                                >
                                  -
                                </span>
                                <input
                                  type="text"
                                  className="child-count-input"
                                  name="child_count"
                                  value={SearchData.childern}
                                  disabled
                                />
                                <span
                                  className="child-plus"
                                  onClick={() =>
                                    setSearchData({
                                      ...SearchData,
                                      childern: SearchData.childern + 1,
                                    })
                                  }
                                >
                                  +
                                </span>{" "}
                              </div>
                              <div
                                id="child-container"
                                className="row w-100 m-0 no-gutters"
                              ></div>
                            </div>
                          </li> */}
                  </ul>
                </div>
              ) : null}



            </form>
          </div>
        </div>
      </div>
      <div
        class="searchbox-backdrop position-fixed w-100 h-100"
        style={{
          display:
            SearchData.showDropdown ||
              SearchData.showCalender ||
              SearchData.showsearch
              ? "block"
              : "none",
        }}
        onClick={() =>
          setSearchData({
            ...SearchData,
            showDropdown: false,
            showCalender: false,
            showsearch: false,
          })
        }
      ></div>
    </section>
  );
}

export default SearchBox;
